import { DatePipe } from '@angular/common';
import { LaravelInvoiceService } from 'src/app/_services/laravel/laravel-invoice.service';

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, timer } from 'rxjs';
import { delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Invoice } from 'src/app/_models/invoice';
import { AlertService } from 'src/app/_services/alert.service';
import { AppState } from 'src/app/_store/app.reducer';
import * as InvoiceActions from 'src/app/home/invoices/store/invoice.actions';
import * as InvoiceSelectors from 'src/app/home/invoices/store/invoice.selectors';
import { DialogRef } from '@angular/cdk/dialog';
import { PAYMENT_METHODS, PAYMENT_TIMES } from 'src/app/_models/org';
import { Actions, ofType } from '@ngrx/effects';
import { SendInvoiceDialogComponent } from '../../send-invoice-dialog/send-invoice-dialog.component';
import { BillingLinesColumn } from 'src/app/home/billing-lines/billing-line-shared/billing-line-list/billing-line-list.component';


@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss'],
  providers: [DatePipe]
})
export class InvoiceEditComponent implements OnInit {
  reset$ = new Subject<void>();
  private unsubscribe$ = new Subject<void>();
  private _invoice: Invoice;
  checkResponse: boolean;

  currentInvoice: Invoice;
  paymentMethods = PAYMENT_METHODS;
  paymentTimes = PAYMENT_TIMES;

  invoiceForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: DialogRef<InvoiceEditComponent>, private actions$: Actions, private invoiceService: LaravelInvoiceService, private dialog: MatDialog, public datepipe: DatePipe
  ) {
    this.reset$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.ngOnChanges());

  }

  ngOnInit() {
    if (this.data) {
      this.invoice = this.data.invoice;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.reset$.complete();
  }

  private _createForm() {
    let group = {
      identifier: ["", Validators.required],
      code: ["", { validators: [Validators.required], asyncValidators: [this.validateCode(this.invoiceService)] }],
      date: ["", Validators.required],
      client: ["", Validators.required],
      paymentMethod: ["", Validators.required],
      paymentTime: ["", Validators.required],
      payed: ["", Validators.required],
      billingLines: [],
    }
    this.invoiceForm = this.fb.group(group);


  }

  validateCode(invoiceService: LaravelInvoiceService): AsyncValidatorFn {

    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      //       setTimeout(() => {
      //   this.store$.dispatch(InvoiceActions.checkInvoiceCode({ invoiceId: this.invoice?.objectId, code: control.value }))
      // }, 500)
      //       return this.actions$.pipe(
      //   ofType(InvoiceActions.checkInvoiceCodeCompleted),
      //   takeUntil(this.unsubscribe$),
      //   tap(({response}) => console.log(response)),

      //   map(({ response }) => {
      //     console.log(response)
      //     return response ? { "codeExists": true } : null})

      // )
      return invoiceService.checkInvoiceCode(this.invoice ? this.invoice.objectId : null, control.value).pipe(
        delay(500),
        map(
          (response) => {
            console.log(response);

            return (response) ? { "codeExists": true } : null;
          }
        ));
    };
  }


  private ngOnChanges() {
    if (this.invoiceForm) {
      this.invoiceForm.reset();
      if (this.invoice) {
        this._initFormValues(this.invoice);
      }
    }

  }

  openInvoicePDF() {
    if (this.invoice) {
      let date = this.datepipe.transform(this.invoice.date, 'dd-MM-yyyy')
      this.dialog.open(SendInvoiceDialogComponent, {
        disableClose: false,
        width: "80%",
        data: {
          invoice: this.invoice,
          subject: `Fattura di cortesia n° ${this.invoice.identifier}`,
          template: `Gentilissimi,<br/>
            <br/>
            Inviamo in allegato la fattura di cortesia n° ${this.invoice.identifier} del ${date}. <br/>
            <br/>
            <b>GEM BB S.r.l.</b><br/>
            Tel. 0549960151`
        }
      })
    }
  }

  private _initFormValues(invoice: Invoice) {
    if (invoice) {
      this.invoiceForm.patchValue({
        identifier: invoice.identifier,
        code: invoice.code,
        date: invoice.date,
        client: invoice.client,
        paymentMethod: invoice.paymentMethod,
        paymentTime: invoice.paymentTime,
        payed: invoice.payed,
        billingLines: invoice.billingLines
      });
    }
  }

  get invoice(): Invoice {
    return this._invoice;
  }

  set invoice(invoice: Invoice) {
    this._invoice = invoice;
    this.ngOnChanges();
  }

  get billingLinesColumns(): BillingLinesColumn[] {
    return ["date", "description", "amount", "client", "article", "activityId", "projects", "invoiced", "actions"];
  }

  private _prepareSaveInvoice(): Invoice {
    let savingInvoice: Invoice = Invoice.fromFormGroup(this.invoiceForm, this.invoice);
    return savingInvoice;
  }

  save() {
    let unsavedEntity = this._prepareSaveInvoice();
    this.store$.dispatch(InvoiceActions.saveInvoice({ invoice: unsavedEntity }));
  }

  close() {
    if (this.invoiceForm.pristine) {
      this.store$.dispatch(InvoiceActions.closeInvoiceDialog({ dialogId: this.dialogRef.id }))
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(InvoiceActions.closeInvoiceDialog({ dialogId: this.dialogRef.id }))
          }
        });
    }
  }

  cancelInvoice() {
    if (this.invoice) {
      this.store$.dispatch(InvoiceActions.editCancelInvoice({ invoice: this.invoice }))
    }
  }

  revert() {
    this.reset$.next();
  }
  invoiceXML() {
    if (this.invoice) {
      this.store$.dispatch(InvoiceActions.invoiceXML({ invoiceId: this.invoice.objectId }))
    }
  }
  invoicePDF() {
    if (this.invoice) {
      this.store$.dispatch(InvoiceActions.invoicePDF({ invoiceId: this.invoice.objectId }))
    }
  }
}
