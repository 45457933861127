<app-project-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-project-filters>
<mat-card class="u-center-text" *ngIf="!(projects | async).length">
  <h4>Nessuna commessa trovata</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addProject()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(projects | async).length">
  <table mat-table matSort [dataSource]="projects">
    <ng-container matColumnDef="identifier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Identificativo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.identifier }}
        <span *ngIf="row.class == 'Preventivo' && row.totalBilled < row.total"><mat-icon class="red"
            matTooltip="Attenzione, il totale fatturato è inferiore al totale preventivato!">warning</mat-icon></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Cliente</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="!row.multiclient">
          {{ row.client?.name }}
        </span>
        <span *ngIf="row.multiclient" class="chip">
          Mutlicliente
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef>Area</th>
      <td mat-cell *matCellDef="let row">
        {{ row.area }}
      </td>
    </ng-container>
    <ng-container matColumnDef="course_id">
      <th mat-header-cell *matHeaderCellDef>Corso</th>
      <td mat-cell *matCellDef="let row">
        {{ row.plannedCourse?.service?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Descrizione</th>
      <td mat-cell *matCellDef="let row">
        {{ row.description }}
        <ng-container *ngIf="row.endDate as endDate">
          <span *ngIf="row.closed" class="chip chip--completed"
            matTooltip="Conclusa il {{endDate | date : 'dd/MM/yyyy'}}">Conclusa</span>
          <span *ngIf="row.closing" class="chip chip--planned"
            matTooltip="In conclusione il {{endDate | date : 'dd/MM/yyyy'}}">In conclusione</span>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="class">
      <th mat-header-cell *matHeaderCellDef>Classe</th>
      <td mat-cell *matCellDef="let row">
        {{ row.class }}
      </td>
    </ng-container>
    <ng-container matColumnDef="offer_number">
      <th mat-header-cell *matHeaderCellDef>Numero offerta</th>
      <td mat-cell *matCellDef="let row">
        {{ row.offerNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="accountant">
      <th mat-header-cell *matHeaderCellDef>Responsabile</th>
      <td mat-cell *matCellDef="let row">
        {{ row.accountant?.fullName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="order_number">
      <th mat-header-cell *matHeaderCellDef>Ordine</th>
      <td mat-cell *matCellDef="let row">
        {{ row.orderNumber }}
        <span *ngIf="row.orderDate">{{
          row.orderDate | date : "dd/MM/yyyy"
          }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="start_date">
      <th mat-header-cell *matHeaderCellDef>Data apertura</th>
      <td mat-cell *matCellDef="let row">
        {{ row.startDate | date : "dd/MM/yyyy" }}
        <ng-container *ngIf="row.closed">
          - Conclusa il {{ row.endDate | date : "dd/MM/yyyy" }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation(); addProject()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectProject(row)">
          Seleziona
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="new_tab">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation(); addProject()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <a matTooltip="Apri in una nuova scheda" [href]="'project/' + row.objectId" target="_blank"
          (click)="$event.stopPropagation()">
          <mat-icon>launch</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showProject(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="25" [length]="total | async" showFirstLastButtons>
  </mat-paginator>
</div>