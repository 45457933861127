<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Descrizione</mat-label>
        <input type="text" matInput formControlName="name">
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Codice Mexal</mat-label>
        <input type="text" matInput formControlName="code">
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Area</mat-label>
        <mat-select formControlName="area">
          <mat-option *ngFor="let area of ARTICLE_AREAS" [value]="area">{{area}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always" *ngIf="showExpenseFilter">
        <mat-label>Voce di spesa</mat-label>
        <mat-select formControlName="expense">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option value="true">Sì</mat-option>
          <mat-option value="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">Filtra</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>