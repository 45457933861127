import { Pipe } from "@angular/core";
import { PlannedCourse } from "src/app/_models/planning";

@Pipe({ name: "coursesPipe", pure: true })
export class CoursesPipe {
  transform(value: PlannedCourse[]): string {
    if (value && value.length) {
      return value
        .map(
          (plannedCourse) =>
            `${plannedCourse.service?.code} - ${plannedCourse.service?.name}`
        )
        .join(", ");
    }
    return "";
  }
}
