<app-billing-line-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-billing-line-filters>
<div class="u-center-text" *ngIf="!billingLines?.length">
  <h4>Nessuna rata di fatturazione trovata</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addBillingLine()">Aggiungi</button>
</div>
<div class="table-container" [hidden]="!billingLines?.length">
  <table mat-table matSort [dataSource]="billingLines">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let row">
        {{ row.date | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="orgId">
      <th mat-header-cell *matHeaderCellDef>OrgId</th>
      <td mat-cell *matCellDef="let row">
        {{ row.orgId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Descrizione</th>
      <td mat-cell *matCellDef="let row">
        {{ row.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="articleId">
      <th mat-header-cell *matHeaderCellDef>ArticleId</th>
      <td mat-cell *matCellDef="let row">
        {{ row.articleId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Importo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.amount | currencyLocal }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activityId">
      <th mat-header-cell *matHeaderCellDef>ID Attività</th>
      <td mat-cell *matCellDef="let row">
        {{ row.activityId }} <mat-icon *ngIf="row.costId"
          matTooltip="Relativa ad un costo extra">monetization_on</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="org">
      <th mat-header-cell *matHeaderCellDef>Cliente</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="row.org">
          <span *ngIf="row.org.partner" class="chip">Partner</span>
          {{ row.org.name }}
        </div>
        <div *ngIf="row.project?.client">
          {{row.project?.client.name}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>Persona</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="row.client">
          {{ row.client.fullName }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="article">
      <th mat-header-cell *matHeaderCellDef>Articolo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.article?.stringRepresentation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activity">
      <th mat-header-cell *matHeaderCellDef>Activity</th>
      <td mat-cell *matCellDef="let row">
        {{ row.activity?.stringRepresentation }}
      </td>
    </ng-container>

    <ng-container matColumnDef="projects">
      <th mat-header-cell *matHeaderCellDef>ID Commessa</th>
      <td mat-cell *matCellDef="let row">
        <span style="cursor: pointer;" [matTooltip]="'Apri commessa '+ row.project?.identifier"
          (click)="onOpenProject.emit(row.project)">{{row.project?.identifier}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="invoiced">
      <th mat-header-cell *matHeaderCellDef>OC</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.invoice">
          {{ row.invoice?.invoiceCode
          }}<mat-icon matTooltip="pagata" *ngIf="row.invoice?.paid">done_outline</mat-icon>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <ng-container *showFor="['admin']">
          <ng-container *ngIf="!row.invoice">
            <mat-checkbox color="primary" (change)="toggleSelectedBillingLine(row)" (click)="$event.stopPropagation()">
            </mat-checkbox>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation();addBillingLine()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editBillingLine(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
            <button mat-menu-item (click)="detachBillingLine(row)" *ngIf="row?.invoice" matTooltip="Sgancia dall'OC">
              <mat-icon>cancel_presentation</mat-icon>
              Rimuovi OC
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectBillingLine(row)">
          Seleziona
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total"
    showFirstLastButtons>
  </mat-paginator>
</div>
<mat-expansion-panel [expanded]="selectedItems" *showFor="['admin']">
  <div class="button-container">
    <button mat-raised-button color="primary" (click)="generateInvoice()">
      Genera OC
    </button>
  </div>
</mat-expansion-panel>