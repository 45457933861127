import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { LaravelInvoiceService } from 'src/app/_services/laravel/laravel-invoice.service';
import { AppState } from 'src/app/_store/app.reducer';
import * as RouterActions from 'src/app/_store/router.actions';
import * as InvoiceActions from './invoice.actions';
import * as BillingLineSelectors from 'src/app/_store/billing-line.selectors';
import * as BillingLineActions from 'src/app/_store/billing-line.actions';
import * as InvoiceSelectors from './invoice.selectors';
import { NewInvoiceComponent } from '../new-invoice/new-invoice.component';
import { InvoiceEditComponent } from '../invoices-shared/invoice-edit/invoice-edit.component';
import { getBillingLinesTableState } from 'src/app/_store/billing-line.selectors';
import { InvoiceCancelComponent } from '../invoice-cancel/invoice-cancel.component';
import { saveAs } from 'file-saver';






@Injectable()
export class InvoiceEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.saveInvoiceFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.loadInvoices),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.invoiceService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              InvoiceActions.loadInvoicesCompleted({ invoices: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(InvoiceActions.loadInvoicesFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.changePage),
      withLatestFrom(this.store$.select(InvoiceSelectors.getInvoicesTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => InvoiceActions.loadInvoices({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.changeSort),
      withLatestFrom(this.store$.select(InvoiceSelectors.getInvoicesTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => InvoiceActions.loadInvoices({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.changeFilters),
      withLatestFrom(this.store$.select(InvoiceSelectors.getInvoicesTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => InvoiceActions.loadInvoices({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  loadInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.loadInvoice),
      switchMap(({ id }) => {
        return this.invoiceService.getInvoiceById(id)
          .pipe(
            map(result =>
              InvoiceActions.loadInvoiceCompleted({ invoice: result })
            ),
            catchError(error => {
              return of(InvoiceActions.loadInvoiceFailed({ error }))
            })
          )
      })
    )
  );

  editInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceActions.editInvoice),
    map(({ invoice }) => {
      let dialogRef = this.dialog.open(InvoiceEditComponent, {
        data: {
          invoice
        }
      });
      return InvoiceActions.invoiceDialogOpened({ dialogId: dialogRef.id });
    }))
  );
  editCancelInvoice$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceActions.editCancelInvoice),
    map(({ invoice }) => {
      let dialogRef = this.dialog.open(InvoiceCancelComponent, {
        data: {
          invoice
        }
      });
      return InvoiceActions.invoiceDialogOpened({ dialogId: dialogRef.id });
    }))
  );


  saveInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.saveInvoice),
      switchMap(({ invoice }) =>
        this.invoiceService.upsert(invoice.toDTO())
          .pipe(
            map(result =>
              InvoiceActions.saveInvoiceCompleted({ invoice: result })
            ),
            catchError(error => of(InvoiceActions.saveInvoiceFailed({ error })))
          )
      )
    )
  );


  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.saveInvoiceCompleted),
      map(action => action.invoice),
      tap(invoice => this.alertService.showConfirmMessage(`Fattura ${invoice.identifier}/${invoice.code} salvata con successo`)),
      map(() => InvoiceActions.closeInvoiceDialog({}))
    )
  );

  confirmInvoiceGeneration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.confirmNewInvoice),
      map(() => {
        let dialogRef = this.dialog.open(NewInvoiceComponent, { width: "50%" });
        return InvoiceActions.invoiceDialogOpened({ dialogId: dialogRef.id });
      })
    )
  );

  newInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.newInvoice),
      withLatestFrom(this.store$.select(BillingLineSelectors.getSelectedBillineLines)),
      map(([{ mode }, billingLines]) => ({ mode, billing_lines: billingLines.map(obj => obj.toDTO()) })),
      switchMap(({ mode, billing_lines }) =>
        this.invoiceService.new(mode, billing_lines)
          .pipe(
            map(invoices =>
              InvoiceActions.newInvoiceCompleted({ invoices })
            ),
            catchError(error => {
              return of(InvoiceActions.newInvoiceFailed({ error }))
            })
          )
      )
    )
  );

  checkInvoiceCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.checkInvoiceCode),
      switchMap(({ invoiceId, code }) =>
        this.invoiceService.checkInvoiceCode(invoiceId, code)
          .pipe(
            map(response =>
              InvoiceActions.checkInvoiceCodeCompleted({ response })
            ),
            catchError(error => {
              return of(InvoiceActions.checkInvoiceCodeFailed({ error }))
            })
          )
      )
    )
  );

  newInvoiceComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.newInvoiceCompleted),
      map(({ invoices }) => invoices.map(invoice => `${invoice.identifier}/${invoice.code}`).join(', ')),
      tap((invoices) => this.alertService.showConfirmMessage(`OC ${invoices} creati con successo`)),
      map(() => InvoiceActions.closeInvoiceDialog({})),
    )
  );

  cancelInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.cancelInvoice),
      mergeMap(({ invoice }) =>
        this.invoiceService.cancel(invoice)
          .pipe(
            map(result =>
              InvoiceActions.cancelInvoiceCompleted({ invoice: result })
            ),
            catchError(error => of(InvoiceActions.cancelInvoiceFailed({ error })))
          )
      )
    )
  );

  onCancelCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.cancelInvoiceCompleted),
      tap(({ invoice }) => this.alertService.showConfirmMessage(`Fattura ${invoice.identifier}/${invoice.code} annullata con successo`)),
      map(() => InvoiceActions.closeInvoiceDialog({})),
    )
  );

  onDeleteCompletedCloseModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.cancelInvoiceCompleted),
      map(() => InvoiceActions.closeInvoiceDialog({})),
    )
  );


  closeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.closeInvoiceDialog),
      withLatestFrom(this.store$.select(InvoiceSelectors.getInvoiceDialogId)),
      map(([{ dialogId }, withLatestFrom]) => dialogId || withLatestFrom),
      tap((id) => { this.dialog.getDialogById(id)?.close(); })
    ), { dispatch: false }
  );

  reloadAfterSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.saveInvoiceCompleted, InvoiceActions.invoiceXMLCompleted, InvoiceActions.invoicesXMLCompleted, InvoiceActions.cancelInvoiceCompleted),
      withLatestFrom(this.store$.select(InvoiceSelectors.getInvoicesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => InvoiceActions.loadInvoices({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );


  resetSelectedInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.loadInvoicesCompleted),
      map(() => (InvoiceActions.resetSelectedInvoices())),
    )
  );

  generateXMLCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.invoiceXMLCompleted),
      map(() => InvoiceActions.closeInvoiceDialog({}))
    )
  );


  // selectInvoice$ = createEffect(() => this.actions$.pipe(
  //   ofType(InvoiceActions.selectInvoice),
  //   map(({filters}) => {
  //     let dialogRef = this.dialog.open(InvoiceSelectionComponent, {
  //       data: {
  //         defaultFilters: filters
  //       }
  //     });
  //     return InvoiceActions.selectionDialogOpened({selectionDialogId: dialogRef.id});
  //   }))
  // );

  closeSelectionDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(InvoiceSelectors.getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  InvoicesSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.invoiceSelected),
      map(() => InvoiceActions.closeSelectionDialog())
    ),
  )

  detachBillingLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.detachBillingLine),
      switchMap(({ billingLine }) =>
        this.invoiceService.detach(billingLine)
          .pipe(
            map(result =>
              InvoiceActions.detachBillingLineCompleted({ billingLine: result })
            ),
            catchError(error => of(InvoiceActions.detachBillingLineFailed({ error })))
          )
      )
    )
  );

  onDetachCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.detachBillingLineCompleted),
      map(action => action.billingLine),
      tap(billingLine => this.alertService.showConfirmMessage(`Rata di fatturazione ${billingLine.id} ripristinata con successo`)),
      withLatestFrom(this.store$.select(getBillingLinesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => (
        BillingLineActions.loadBillingLines({ page: currentPage, perPage, order, direction, filters, includes })
      )),
    )
  )

  XMLinvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.invoiceXML),
      switchMap(({ invoiceId }) =>
        this.invoiceService.getInvoiceXML(invoiceId).pipe(
          map((result) =>
            InvoiceActions.invoiceXMLCompleted({
              blob: new Blob([result], { type: "invoice/xml" }),
            })
          ),
          tap(() =>
            this.alertService.showConfirmMessage(
              `XML generato con successo`
            )
          ),
          catchError((error) =>
            of(InvoiceActions.invoiceXMLFailed({ error }))
          )
        )
      )
    )
  );

  downloadXML$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoiceActions.invoiceXMLCompleted),
        tap(({ blob }) => {
          saveAs(blob, `fattura.xml`);
        })
      ),
    { dispatch: false }
  );

  XMLinvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.invoicesXML),
      switchMap(({ ids }) =>
        this.invoiceService.getInvoicesXML(ids).pipe(
          map((result) =>
            InvoiceActions.invoicesXMLCompleted({
              blob: new Blob([result], { type: "application/x-zip" }),
            })
          ),
          tap(() =>
            this.alertService.showConfirmMessage(
              `ZIP generato con successo`
            )
          ),
          catchError((error) =>
            of(InvoiceActions.invoicesXMLFailed({ error }))
          )
        )
      )
    )
  );

  downloadXMLZIP$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoiceActions.invoicesXMLCompleted),
        tap(({ blob }) => {
          saveAs(blob, `OC.zip`);
        })
      ),
    { dispatch: false }
  );

  PDFinvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.invoicePDF),
      switchMap(({ invoiceId }) =>
        this.invoiceService.getInvoicePDF(invoiceId).pipe(
          map((result) =>
            InvoiceActions.invoicePDFCompleted({
              blob: new Blob([result], { type: "invoice/pdf" }),
            })
          ),
          tap(() =>
            this.alertService.showConfirmMessage(
              `PDF generato con successo`
            )
          ),
          catchError((error) =>
            of(InvoiceActions.invoicePDFFailed({ error }))
          )
        )
      )
    )
  );

  downloadPDF$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InvoiceActions.invoicePDFCompleted),
        tap(({ blob }) => {
          saveAs(blob, `fattura.pdf`);
        })
      ),
    { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private invoiceService: LaravelInvoiceService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
