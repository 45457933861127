import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Participant } from "./../_models/planning";
import { LaravelMailService } from "./laravel/laravel-mail.service";

@Injectable({ providedIn: "root" })
export class MailService {
  constructor(private laraverlMailService: LaravelMailService) {}

  sendMailToParticipants(
    participants: Participant[],
    planned_unit_id: number,
    template: string,
    subject: string,
    sender: string,
    senderInBcc: boolean = false,
    includeCertificates: boolean = false,
    includeParticipants: boolean = false
  ): Observable<boolean> {
    return this.laraverlMailService.sendMailParticipant(
      sender,
      subject,
      template,
      participants.map((p) => p.objectId),
      planned_unit_id,
      senderInBcc,
      includeCertificates,
      includeParticipants
    );
  }

  sendMail(
    recipients: string[],
    template: string,
    subject: string,
    sender: string,
    senderInBcc: boolean = false
  ): Observable<boolean> {
    return this.laraverlMailService.sendMail(
      sender,
      subject,
      template,
      recipients,
      senderInBcc
    );
  }

  sendPDF(
    invoice_id: number,
    template: string,
    subject: string,
    sender: string,
    senderInBcc: boolean = false
  ): Observable<boolean> {
    return this.laraverlMailService.sendPDF(
      sender,
      subject,
      template,
      invoice_id,
      senderInBcc
    );
  }
}
