import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { BillingLine } from 'src/app/_models/billing-line.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/_helpers/table.helper';
import { BillingLineFilters } from 'src/app/_models/billing-line.model';
import { Project } from 'src/app/_models/project';

export type BillingLinesColumn = "id" | "date" | "orgId" | "description" | "articleId" | "amount" | "activityId" | "org" | "client" | "article" | "activity" | "actions" | "projects" | "invoiced" | "selected";

@Component({
  selector: 'billing-line-list',
  templateUrl: './billing-line-list.component.html',
  styleUrls: ['./billing-line-list.component.scss'],
})
export class BillingLineListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  billingLines: BillingLine[];

  @Input()
  defaultFilters: Partial<BillingLineFilters>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: BillingLine | null;

  @Input()
  selectedBillingLines: BillingLine[];

  @Input()
  total: number;
  @Input()
  displayedColumns: BillingLinesColumn[] = ["selected", "date", "description", "amount", "org", "client", "article", "activityId", "projects", "invoiced", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BillingLineFilters> = new EventEmitter<BillingLineFilters>();

  @Output()
  onSelectBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onAddBillingLine: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onAddSelectedBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onRemoveSelectedBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onGenerateInvoice: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onDetachBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onOpenProject: EventEmitter<Project> = new EventEmitter<Project>();

  constructor() { }

  get selectedItems(): boolean {
    return this.selectedBillingLines?.length > 0;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  isSelected(billingLine: BillingLine): boolean {
    return this.selectedBillingLines?.map(obj => obj.objectId).includes(billingLine.objectId)
  }
  addSelectedBillingLine(billingLine: BillingLine) {
    this.onAddSelectedBillingLine.emit(billingLine)
  }

  removeSelectedBillingLine(billingLine: BillingLine) {
    this.onRemoveSelectedBillingLine.emit(billingLine)
  }

  addBillingLine() {
    this.onAddBillingLine.emit();
  }

  editBillingLine(billingLine?: BillingLine) {
    this.onEditBillingLine.emit(billingLine);
  }

  selectBillingLine(billingLine: BillingLine) {
    this.onSelectBillingLine.emit(billingLine);
  }

  onFilter(filters: BillingLineFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
  toggleSelectedBillingLine(billingLine: BillingLine) {
    if (this.isSelected(billingLine)) {
      this.removeSelectedBillingLine(billingLine);
    } else {
      this.addSelectedBillingLine(billingLine);
    }
  }
  generateInvoice() {
    this.onGenerateInvoice.emit();
  }
  detachBillingLine(billingLine: BillingLine) {
    this.onDetachBillingLine.emit(billingLine);
  }

}
