import { BillingLine } from 'src/app/_models/billing-line.model';
import { BillingLineDTO } from './billing-line.model';
import { Time } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { AbstractControl } from '@angular/forms';

import { formatDateForBackend, timeToMinutes } from '../_helpers/utils';
import { Article, ArticleDTO } from './article';
import { Base, BaseDTO } from './base';
import { Invoice } from './invoice';
import { Org, OrgDTO } from './org';
import { Project, ProjectDTO } from './project';
import { Question, QuestionDTO } from './question';
import { User, UserDTO } from './user';
import { PlannedUnit, PlannedUnitDTO } from './planning';


export interface ActivityFilters {
  org: OrgDTO;
  project: ProjectDTO;
  user_ids: number[];
  date_from: Date;
  date_to: Date;
  start: string;
  entire_day: boolean;
  to_bill: boolean;
  projectId: number;
}

export interface ActivityDTO extends BaseDTO {
  org_id?: number;
  user_ids: number[];
  project_id: number;
  article_id: number;
  planned_unit_id?: number;
  start: string;
  end: string;
  minutes: number;
  description: string;
  notes: string;
  to_bill: boolean;
  entire_day: boolean;
  planned_unit?: PlannedUnitDTO;
  org?: OrgDTO;
  users: UserDTO[];
  project: ProjectDTO;
  article: ArticleDTO;
  questions: QuestionDTO[];
  billing_lines: BillingLineDTO[];

}

export class Activity extends Base {
  orgId: number;
  userIds: number[];
  projectId: number;
  articleId: number;
  plannedUnitId: number;
  date: Date;
  startDate: Date;
  endDate: Date;
  minutes: number;
  description: string;
  notes: string;
  toBill: boolean;
  entireDay: boolean;

  org: Org;
  plannedUnit: PlannedUnit
  users: User[];
  project: Project;
  article: Article;
  questions: Question[];
  billingLines: BillingLine[];

  constructor(dto: ActivityDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      if (dto.org) {
        this.org = new Org(dto.org);
        this.addLoadedRelation("org");
      }
      if (dto.users) {
        this.users = dto.users.map(user => new User(user));
        this.addLoadedRelation("users");
      }
      if (dto.project) {
        this.project = new Project(dto.project);
        this.addLoadedRelation("project");
      }
      if (dto.article) {
        this.article = new Article(dto.article);
        this.addLoadedRelation("article");
      }
      if (dto.planned_unit) {
        this.plannedUnit = new PlannedUnit(dto.planned_unit);
        this.addLoadedRelation("planned_unit");
      }
      if (dto.questions) {
        this.questions = dto.questions.map(question => new Question(question));
        this.addLoadedRelation("questions");
      }
      if (dto.billing_lines) {
        this.billingLines = dto.billing_lines.map(b => new BillingLine(b));
        this.addLoadedRelation("billing_lines");
      }

      this.orgId = dto.org_id;
      this.userIds = dto.user_ids;
      this.projectId = dto.project_id;
      this.articleId = dto.article_id;
      this.date = new Date(dto.start);
      this.startDate = new Date(dto.start);
      this.endDate = new Date(dto.end);
      this.minutes = dto.minutes;
      this.notes = dto.notes;
      this.description = dto.description;
      this.toBill = dto.to_bill;
      this.entireDay = dto.entire_day;
    }
  }

  public toDTO(): ActivityDTO {
    const result: ActivityDTO = <ActivityDTO>super.toDTO()
    result.org = this.org ? this.org.toDTO() : null;
    result.org_id = this.org?.objectId;
    result.planned_unit = this.plannedUnit ? this.plannedUnit.toDTO() : null;
    result.planned_unit_id = this.plannedUnit?.objectId;
    result.questions = this.questions ? this.questions.map(question => question.toDTO()) : null;
    result.billing_lines = this.billingLines ? this.billingLines.map(b => b.toDTO()) : null;
    result.users = this.users ? this.users.map(user => user.toDTO()) : null;
    result.user_ids = this.users ? this.users.map(u => u.objectId) : this.userIds;
    result.project = this.project ? this.project.toDTO() : null;
    result.project_id = this.project?.objectId;
    result.start = formatDateForBackend(this.startDate, true);
    result.end = formatDateForBackend(this.endDate, true);
    result.article = this.article ? this.article.toDTO() : null;
    result.minutes = this.minutes;
    result.notes = this.notes;
    result.description = this.description;
    result.to_bill = this.toBill;
    result.entire_day = this.entireDay;
    result.article_id = this.articleId;


    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Activity): Activity {
    const formModel = formGroup.getRawValue();

    let activity: Activity = new Activity(null);
    activity.org = formModel.org;
    activity.users = formModel.users;
    activity.userIds = formModel.users?.map((user: User) => user.objectId);
    activity.project = formModel.project;
    activity.date = formModel.date;
    activity.end = formModel.end;
    activity.start = formModel.start;
    activity.minutes = timeToMinutes(formModel.minutes);
    activity.notes = formModel.notes;
    activity.description = formModel.description;
    activity.toBill = formModel.toBill ? true : false;
    activity.entireDay = formModel.entireDay ? true : false;
    activity.article = formModel.article;
    activity.articleId = formModel.article?.objectId;


    if (original) {
      activity.objectId = original.objectId;
      activity.loadedRelations = original.loadedRelations;
    }

    return activity;
  }

  get stringRepresentation() {
    return `${this.users?.map(u => u.name).join(',')} - ${this.project?.identifier} - ${this.endDate.toLocaleDateString()}`
  }

  get start(): Time {
    return {
      hours: this.startDate.getHours(),
      minutes: this.startDate.getMinutes(),
    }
  }
  get end(): Time {
    return {
      hours: this.endDate.getHours(),
      minutes: this.endDate.getMinutes(),
    }
  }
  get hours(): number {
    return (this.end.hours - this.start.hours)
  }

  get activityInvoice(): Invoice {
    let invoice: Invoice;
    this.billingLines?.forEach(b => {
      if (b.activityId == this.objectId) {
        invoice = b.invoice;
      }
    })
    return invoice

  }

  set start(value: Time) {
    let date = new Date(this.date);
    date.setHours(value.hours);
    date.setMinutes(value.minutes);
    this.startDate = date;
  }

  set end(value: Time) {
    let date = new Date(this.date);
    date.setHours(value.hours);
    date.setMinutes(value.minutes);
    this.endDate = date;
  }

  get readableUsers() {
    return this.users?.map(user => user.fullName).join(', ');
  }
}
