<!-- <app-activity-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-activity-filters> -->
<mat-card class="u-center-text" *ngIf="!(activities | async).length">
  <h4>Nessuna attività trovata</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addActivity()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(activities | async).length">
  <table mat-table matSort [dataSource]="activities">
    <!--
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox>
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" >
        </mat-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item >
            <mat-icon>school</mat-icon>
            <span>Genera Rate</span>
          </button>
        </mat-menu>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="primary"
          (click)="$event.stopPropagation(); addActivity()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button *ngIf="!canSelect" [mat-menu-trigger-for]="actionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
          <button mat-menu-item (click)="editActivity(row)">
            <mat-icon>create</mat-icon>
            Modifica
          </button>
          <button *ngIf="!row.billingLines?.length && !row.plannedUnit" mat-menu-item
            (click)="generateBillingLine(row)">
            <mat-icon>monetization_on</mat-icon>
            Genera rata
          </button>
          <button *ngIf="row.activityInvoice" mat-menu-item (click)="viewInvoice(row)">
            <mat-icon>request_quote</mat-icon>Fattura
          </button>
        </mat-menu>

        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectActivity(row)">
          Seleziona
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">
        {{ row.objectId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef>Utenti</th>
      <td mat-cell *matCellDef="let row">
        {{row.readableUsers}}
      </td>
    </ng-container>
    <ng-container matColumnDef="org">
      <th mat-header-cell *matHeaderCellDef>Cliente</th>
      <td mat-cell *matCellDef="let row">
        {{ row.org?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef>Commessa</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }}
      </td>
    </ng-container>
    <ng-container matColumnDef="article">
      <th mat-header-cell *matHeaderCellDef>Articolo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.article?.stringRepresentation }}
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let row">
        {{ row.date | date : "dd-MM-yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef>Inizio</th>
      <td mat-cell *matCellDef="let row">
        {{
        (row.start.hours | number : "2.0") +
        ":" +
        (row.start.minutes | number : "2.0")
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef>Fine</th>
      <td mat-cell *matCellDef="let row">
        {{
        (row.end.hours | number : "2.0") +
        ":" +
        (row.end.minutes | number : "2.0")
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="minutes">
      <th mat-header-cell *matHeaderCellDef>Minuti da sottrarre</th>
      <td mat-cell *matCellDef="let row">
        {{ row.minutes }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Descrizione</th>
      <td mat-cell *matCellDef="let row">
        {{ row.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef>Note</th>
      <td mat-cell *matCellDef="let row">
        {{ row.notes }}
      </td>
    </ng-container>
    <ng-container matColumnDef="toBill">
      <th mat-header-cell *matHeaderCellDef>Da fatturare</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.toBill == true" class="true-icon">check_circle_outline</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="entireDay">
      <th mat-header-cell *matHeaderCellDef>Intera giornata</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.entireDay == true" class="true-icon">check_circle_outline</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="billingLines">
      <th mat-header-cell *matHeaderCellDef>Rata di fatturazione</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.billingLines?.length" class="true-icon"
          matTooltip="Rata di fatturazione generata per questa attività">check_circle_outline</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showActivity(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="25" [length]="total | async" showFirstLastButtons>
  </mat-paginator>
</div>