import { Base, BaseDTO } from "./base";

export type Role = "admin" | "organizer" | "operator";
export type DailyHours = 4 | 5 | 6 | 7.5 | 8;


export interface UserFilters {
  active?: boolean;
}
export interface UserDTO extends BaseDTO {
  username: string;
  password?: string;
  email: string;
  name: string;
  surname: string;
  gender?: string;
  profile_pic?: string;
  calendar_id: string;
  user_roles: Role[];
  educational_qualification?: string;
  active?: boolean;
  km_cost?: number;
  hour_cost?: number;
  daily_hours?: DailyHours;
  calendar_color?: string;
  sync_calendar?: boolean;
  billable?: boolean;
  active_login?: boolean;
  business_phone: string;
  private_phone: string;
  competence_area: string;
  note: string;
}

export class User extends Base {
  username: string;
  password?: string;
  email: string;
  name: string;
  surname: string;
  gender?: string;
  profilePic?: string;
  calendarId: string;
  role: Role;
  educationalQualification?: string;
  active?: boolean;
  kmCost?: number;
  hourCost?: number;
  dailyHours?: DailyHours;
  calendarColor?: string;
  syncCalendar?: boolean;
  billable?: boolean;
  activeLogin?: boolean;
  businessPhone: string;
  privatePhone: string;
  competenceArea: string;
  note: string;

  constructor(dto: UserDTO) {
    super(dto);
    if (dto) {
      this.username = dto.username;
      this.name = dto.name;
      this.surname = dto.surname;
      this.email = dto.email;
      this.gender = dto.gender;
      this.profilePic = dto.profile_pic;
      this.calendarId = dto.calendar_id;
      if (dto.user_roles) {
        this.role = dto.user_roles[0];
      }
      this.educationalQualification = dto.educational_qualification;
      this.active = dto.active;
      this.kmCost = dto.km_cost;
      this.hourCost = dto.hour_cost;
      this.dailyHours = dto.daily_hours;
      this.calendarColor = dto.calendar_color;
      this.syncCalendar = dto.sync_calendar;
      this.billable = dto.billable;
      this.activeLogin = dto.active_login;
      this.businessPhone = dto.business_phone;
      this.privatePhone = dto.private_phone;
      this.competenceArea = dto.competence_area;
      this.note = dto.note;
    }
  }

  public toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.username = this.username;
    result.name = this.name;
    result.surname = this.surname;
    result.email = this.email;
    result.gender = this.gender;
    result.profile_pic = this.profilePic;
    result.password = this.password;
    result.user_roles = [];
    if (this.role) {
      result.user_roles.push(this.role);
    }
    result.educational_qualification = this.educationalQualification;
    result.active = this.active;
    result.km_cost = this.kmCost;
    result.hour_cost = this.hourCost;
    result.daily_hours = this.dailyHours;
    result.calendar_color = this.calendarColor;
    result.sync_calendar = this.syncCalendar;
    result.billable = this.billable;
    result.active_login = this.activeLogin;
    result.business_phone = this.businessPhone;
    result.private_phone = this.privatePhone;
    result.competence_area = this.competenceArea;
    result.note = this.note;

    return result;
  }

  get fullName() {
    return `${this.name ?? ''} ${this.surname ?? ''}`;
  }

  get admin(): boolean {
    return this.hasRole("admin");
  }

  get organizer(): boolean {
    return this.hasRole("organizer");
  }
  get operator(): boolean {
    return this.hasRole("operator");
  }

  hasRole(role: Role): boolean {
    return this.role && this.role === role;
  }
}
