import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { PAGE_SIZE_OPTIONS } from 'src/app/_helpers/table.helper';
import { Activity, ActivityFilters } from 'src/app/_models/activity';
import { Observable } from 'rxjs';



export type ActivitiesColumn = "id" | "org" | "users" | "project" | "article" | "date" | "start" | "end" | "minutes" | "description" | "notes" | "toBill" | "entireDay" | "actions" | "select" | "billingLines";

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  activities: Observable<Activity[]>;

  @Input()
  defaultFilters: Partial<ActivityFilters>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Activity | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ActivitiesColumn[] = ["id", "org", "project", "users", "article", "date", "start", "end", "minutes", "description", "notes", "toBill", "entireDay", "billingLines", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<Partial<ActivityFilters>> = new EventEmitter<Partial<ActivityFilters>>();

  @Output()
  onSelectActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onAddActivity: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onShowActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onGenerateBillingLine: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onExportActivities: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onViewInvoice: EventEmitter<Activity> = new EventEmitter<Activity>();

  constructor() { }



  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addActivity() {
    this.onAddActivity.emit();
  }

  editActivity(activity: Activity) {
    this.onEditActivity.emit(activity);
  }

  selectActivity(activity: Activity) {
    this.onSelectActivity.emit(activity);
  }
  showActivity(activity: Activity) {
    this.onShowActivity.emit(activity);
  }

  onFilter(filters: Partial<ActivityFilters>) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  generateBillingLine(activity: Activity) {
    this.onGenerateBillingLine.emit(activity);
  }

  exportActivityReport() {
    this.onExportActivities.emit();
  }

  viewInvoice(activity: Activity) {
    this.onViewInvoice.emit(activity);
  }
}
