export const environment = {
  production: false,
  envName: "LOCAL",
  parse: {
    serverUrl: "http://docker.scadenziario.it/parse",
    appId: "scadenziario-parse-app-id",
    javascriptKey: "scadenziario-parse-javascript-key"
  },
  laravel: {
    serverUrl: "http://localhost:8000"
  }
};
