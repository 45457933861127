<mat-card class="u-center-text" *ngIf="!(costs$ | async).length">
  <h4>Nessun costo presente</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addCost()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(costs$ | async).length">
  <table mat-table matSort [dataSource]="costs$ | async">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button mat-raised-button color="primary" (click)="$event.stopPropagation(); addCost()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
          <button *ngIf="row.isInvoice && !row.billingLines?.length" mat-menu-item (click)="generateBillingLine(row)">
            <mat-icon>monetization_on</mat-icon>
            Genera rata
          </button>
          <button mat-menu-item (click)="editCost(row)">
            <mat-icon>create</mat-icon>
            Modifica
          </button>
        </mat-menu>

        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectCost(row)">
          Seleziona
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">
        {{ row.objectId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="article">
      <th mat-header-cell *matHeaderCellDef>Articolo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.article.name }}
        <mat-icon *ngIf="row.note" [matTooltip]="row.note">
          chat
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="billingLines">
      <th mat-header-cell *matHeaderCellDef>Rata di fatturazione</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.billingLines?.length" class="true-icon"
          matTooltip="Rata di fatturazione generata per questo costo">check_circle_outline</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantità</th>
      <td mat-cell *matCellDef="let row">
        {{ row.quantity }}
      </td>
    </ng-container>
    <ng-container matColumnDef="single_cost">
      <th mat-header-cell *matHeaderCellDef>Costo Singolo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.singleCost | currency : "EUR" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Totale</th>
      <td mat-cell *matCellDef="let row">
        {{ row.costTotal | currency : "EUR" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef>Note</th>
      <td mat-cell *matCellDef="let row">

      </td>
    </ng-container>
    <ng-container matColumnDef="is_invoice">
      <th mat-header-cell *matHeaderCellDef>Da Fatturare</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.isInvoice == true" class="true-icon">check_circle_outline</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showCost(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="25" [length]="total | async" showFirstLastButtons>
  </mat-paginator>
</div>
