import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { PAGE_SIZE_OPTIONS } from 'src/app/_helpers/table.helper';
import { CostExtra, CostExtraFilters } from 'src/app/_models/costExtra';
import { Observable } from 'rxjs';
import { Activity } from 'src/app/_models/activity';

export type CostExtraColumn = "id" | "billingLines" | "article" | "quantity" | "single_cost" | "total" | "is_invoice" | "actions";

@Component({
  selector: 'app-cost-list',
  templateUrl: './cost-list.component.html',
  styleUrls: ['./cost-list.component.scss'],
})
export class CostListComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  activity: Activity;

  @Input()
  costs$: Observable<CostExtra[]>;

  @Input()
  defaultFilters: Partial<CostExtraFilters>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: CostExtraColumn[] = ["id", "billingLines", "article", "quantity", "single_cost", "total", "is_invoice", "actions"];


  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<Partial<CostExtraFilters>> = new EventEmitter<Partial<CostExtraFilters>>();

  @Output()
  onSelectCost: EventEmitter<CostExtra> = new EventEmitter<CostExtra>();
  @Output()
  onAddCost: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditCost: EventEmitter<CostExtra> = new EventEmitter<CostExtra>();
  @Output()
  onShowCost: EventEmitter<CostExtra> = new EventEmitter<CostExtra>();
  @Output()
  onGenerateBillingLine: EventEmitter<CostExtra> = new EventEmitter<CostExtra>();

  constructor() { }



  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addCost() {
    this.onAddCost.emit();
  }

  editCost(cost: CostExtra) {
    this.onEditCost.emit(cost);
  }

  selectCost(cost: CostExtra) {
    this.onSelectCost.emit(cost);
  }
  showCost(cost: CostExtra) {
    this.onShowCost.emit(cost);
  }

  onFilter(filters: Partial<CostExtraFilters>) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  generateBillingLine(cost: CostExtra) {
    this.onGenerateBillingLine.emit(cost);
  }
}
