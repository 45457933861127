<header mat-dialog-title>
  Invia e-mail a {{ recipients.length }} destinatari
</header>
<mat-dialog-content>
  <mat-expansion-panel expanded="false" *ngIf="noMailRecipients().length">
    <mat-expansion-panel-header>
      <mat-panel-title> ATTENZIONE </mat-panel-title>
      <mat-panel-description>
        {{ noMailRecipients().length }} destinatari senza indirizzo e-mail
      </mat-panel-description>
    </mat-expansion-panel-header>
    <h4>Queste persone non hanno un indirizzo e-mail</h4>
    <div *ngFor="let recipient of noMailRecipients()">
      {{ recipient.employee?.fullName }}
    </div>
  </mat-expansion-panel>
  <form [formGroup]="mailForm" *ngIf="recipients.length - noMailRecipients()?.length > 0" novalidate>
    <table style="width: 100%">
      <tr>
        <td style="width: 70%">
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Oggetto" type="text" formControlName="subject" required />
            <mat-error *ngIf="mailForm.controls['subject'].errors">
              Campo obbligatorio
            </mat-error>
          </mat-form-field>
        </td>

        <td style="width: 30%">
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Mittente" type="email" formControlName="sender" required />
          </mat-form-field>
        </td>
      </tr>
    </table>
    <div class="send-options">
      <div>
        <mat-checkbox #tooltip="matTooltip" matTooltip="Invia copia al mittente, in ccn" aria-label="send-in-ccn"
          formControlName="senderInBcc">Copia al mittente
        </mat-checkbox>
        <mat-checkbox *ngIf="!hideIncludeCertificate" #tooltip="matTooltip"
          matTooltip="Allega, se disponibili, gli attestati alla mail" formControlName="includeCertificates">Allega
          attestato
        </mat-checkbox>
        <mat-checkbox #tooltip="matTooltip"
          matTooltip="Invia anche ai singoli partecipanti, oltre che al referente aziendale" aria-label="send-in-ccn"
          formControlName="includeParticipants">Anche ai singoli partecipanti
        </mat-checkbox>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Email Template</mat-label>
          <mat-select formControlName="template" (selectionChange)="onTemplateChange($event.value)">
            <mat-option *ngFor="let layout of templates" [value]="layout.content">{{ layout.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-expansion-panel expanded="false" *ngIf="
        mailForm.value.includeCertificates && noCertificateRecipients().length
      ">
      <mat-expansion-panel-header>
        <mat-panel-title> ATTENZIONE </mat-panel-title>
        <mat-panel-description>
          {{ noCertificateRecipients().length }} destinatari senza attestato
        </mat-panel-description>
      </mat-expansion-panel-header>
      <h4>
        Queste persone non hanno concluso il corso e non riceveranno l'attestato
        in allegato
      </h4>
      <div *ngFor="let recipient of noCertificateRecipients()">
        {{ recipient.employee?.fullName }}
      </div>
    </mat-expansion-panel>
    <div style="padding: 5px">
      <label>Testo della mail</label>
      <ngx-wig formControlName="content"> </ngx-wig>
    </div>
    <ng-container *ngIf="!hidePlaceholders">
      <h6>
        <i>Puoi usare questi codici nel testo della mail: </i>
      </h6>
      <ul>
        <li><strong>$PERSON_NAME$</strong> - Nome della persona</li>
        <li><strong>$PERSON_SURNAME$</strong> - Cognome della persona</li>
        <li><strong>$TITOLO_CORSO$</strong> - Titolo del corso</li>
        <span *ngIf="plannedUnitId">
          <li><strong>$COURSE_LOCATION$</strong> - Sede dell'unità formativa</li>
          <li><strong>$START_TIME$</strong> - Inizio dell'unità formativa (Ore)</li>
          <li><strong>$END_TIME$</strong> - Fine dell'unità formativa (Ore)</li>
          <li><strong>$DATE$</strong> - Data dell'Unità formativa</li>
          <li><strong>$ORDER_ID$</strong> - ID ordine</li>
        </span>
      </ul>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="basic" mat-dialog-close>Annulla</button>
  <button mat-raised-button *ngIf="recipients.length - noMailRecipients()?.length > 0" color="primary" type="button"
    (click)="send()" [disabled]="!mailForm.valid">
    Conferma
  </button>
</mat-dialog-actions>