import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { PAGE_SIZE_OPTIONS } from 'src/app/_helpers/table.helper';
import { Project, ProjectFilters } from 'src/app/_models/project';
import { AppState } from 'src/app/_store/app.reducer';
import * as ProjectActions from 'src/app/home/store/actions/project.actions';
import * as ProjectSelectors from 'src/app/home/store/selectors/project.selectors';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ProjectsColumn } from '../project-list/project-list.component';

@Component({
  selector: 'app-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss']
})
export class ProjectSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  projects: Observable<Project[]>;
  filters: Observable<Partial<ProjectFilters>>;

  @Input()
  defaultFilters: Partial<ProjectFilters> = {};

  displayedColumns: ProjectsColumn[] = ["identifier", "client", "description", "area", "class", "offer_number", "order_number", "accountant", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  private _includes: string[] = [
    "client",
    "accountant",
    "billing_lines"
  ];
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.projects = this.store$.pipe(select(ProjectSelectors.getProjects), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Project(dto)) : null));
    this.total = this.store$.pipe(select(ProjectSelectors.getTotalProjects), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(ProjectSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(ProjectActions.loadProjects(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters, includes: this._includes }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(ProjectActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(ProjectActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: Partial<ProjectFilters>) {
    this.store$.dispatch(ProjectActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectProject(project: Project) {
    if (project) {
      this.store$.dispatch(ProjectActions.projectSelected({ project: project.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(ProjectActions.closeSelectionDialog())
  }
}
