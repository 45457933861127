<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="invoice">Modifica OC: {{ invoice.invoiceCode }} - {{invoice.clientName}}</span>
    <span *ngIf="!invoice">Nuovo OC</span>
  </span>
  <span *ngIf="invoice">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="cancelInvoice()">
        <mat-icon>delete</mat-icon>
        Annulla OC
      </button>
      <button mat-menu-item (click)="invoiceXML()">
        <mat-icon>cloud_download</mat-icon>
        Genera XML
      </button>
      <!-- <button mat-menu-item (click)="invoicePDF()">
        <mat-icon>picture_as_pdf</mat-icon>
        Genera PDF
      </button>
      <button mat-menu-item (click)="openInvoicePDF()">
        <mat-icon>send</mat-icon>
        Invia PDF
      </button> -->
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="invoiceForm" novalidate>
    <mat-checkbox matInput color="primary" formControlName="payed">
      <mat-label>Pagata</mat-label>
    </mat-checkbox>
    <div class="u-flex-full">
      <mat-form-field class="full-width-input">
        <input matInput formControlName="identifier" placeholder="Identificativo fattura" type="number" readonly />
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput formControlName="code" placeholder="Codice fattura" type="number" />
        <mat-error *ngIf="invoiceForm?.controls['code']?.errors?.required">Campo obbligatorio</mat-error>
        <mat-error *ngIf="invoiceForm?.controls['code']?.errors?.codeExists">Codice fattura già esistente</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="date" placeholder="Data" />
        <mat-datepicker #fromDatePicker1></mat-datepicker>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="full-width-input">
        <mat-label>Modalità di pagamento</mat-label>
        <mat-select formControlName="paymentMethod">
          <mat-option *ngFor="let method of paymentMethods" [value]="method">{{
            method
            }}</mat-option>
        </mat-select>
        <mat-error>Metodo di pagamento mancante</mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <mat-label>Tempistiche di pagamento</mat-label>
        <mat-select formControlName="paymentTime">
          <mat-option *ngFor="let time of paymentTimes" [value]="time">{{
            time
            }}</mat-option>
        </mat-select>
        <mat-error>Tempistiche di pagamento mancante</mat-error>
      </mat-form-field>
    </div>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        Rate di fatturazione
      </mat-expansion-panel-header>
      <billing-line-list [billingLines]="invoiceForm?.value?.billingLines"
        [total]="invoiceForm?.value?.billingLines?.length" [displayedColumns]="billingLinesColumns"></billing-line-list>
    </mat-expansion-panel>
  </form>
  <!-- {{invoiceForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()" [disabled]="invoiceForm.pristine">
      Reset
    </button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="invoiceForm.pristine || !invoiceForm.valid">
      Salva
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>