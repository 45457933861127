
import * as CostExtraActions from './cost-extra.actions';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from 'src/app/_services/alert.service';
import { AppState } from 'src/app/_store/app.reducer';
import { LaravelCostExtraService } from '../_services/laravel/laravel-costExtra.service';
import * as CostExtraSelectors from './cost-extra.selectors';
import { CostExtraEditComponent } from '../home/activities/cost-extra/cost-extra-edit.component';
import { CostSelectionComponent } from '../commons/cost-selection/cost-selection.component';
import * as BillingLineSelectors from 'src/app/_store/billing-line.selectors';
import * as BillingLineActions from 'src/app/_store/billing-line.actions';
@Injectable()
export class CostExtraEffects {


  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.saveCostExtraFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadCostExtras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.loadCostExtras),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.costService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              CostExtraActions.loadCostExtrasCompleted({ costExtras: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(CostExtraActions.loadCostExtrasFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.changePage),
      withLatestFrom(this.store$.select(CostExtraSelectors.getCostExtraTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => CostExtraActions.loadCostExtras({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.changeSort),
      withLatestFrom(this.store$.select(CostExtraSelectors.getCostExtraTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => CostExtraActions.loadCostExtras({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );


  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.changeFilters),
      withLatestFrom(this.store$.select(CostExtraSelectors.getCostExtraTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => CostExtraActions.loadCostExtras({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  loadCostExtra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.loadCostExtra),
      switchMap(({ id }) => {
        return this.costService.getCostExtraById(id)
          .pipe(
            map(result =>
              CostExtraActions.loadCostExtraCompleted({ costExtra: result })
            ),
            catchError(error => {
              return of(CostExtraActions.loadCostExtraFailed({ error }))
            })
          )
      })
    )
  );
  // addCostExtra$ = createEffect(() => this.actions$.pipe(
  //   ofType(CostExtraActions.addCostExtra),
  //   map(({costExtra}) => {
  //     let dialogRef = this.dialog.open(CostExtraEditComponent, { //fix da fare
  //       data: {
  //         costExtra
  //       }
  //     });
  //     return CostExtraActions.CostExtraDialogOpened({dialogId: dialogRef.id});
  //   }))
  // );

  editCostExtra$ = createEffect(() => this.actions$.pipe(
    ofType(CostExtraActions.editCostExtra),
    // withLatestFrom(this.store$.select(CostExtraSelectors.getCurrentCostExtra)),
    map(({ costExtra, activity }) => {
      let dialogRef = this.dialog.open(CostExtraEditComponent, {
        data: {
          costExtra,
          activity
        }
      });
      return CostExtraActions.CostExtraDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveCostExtra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.saveCostExtra),
      switchMap(({ costExtra }) =>
        this.costService.upsertCostExtra(costExtra)
          .pipe(
            map(result =>
              CostExtraActions.saveCostExtraCompleted({ costExtra: result })
            ),
            catchError(error => of(CostExtraActions.saveCostExtraFailed({ error })))
          )
      )
    )
  );

  deleteCostExtra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.deleteCostExtra),
      switchMap(({ costExtra }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare l'attività ${costExtra.id}?`)
          .pipe(
            filter(confirm => confirm),
            switchMap(() =>
              this.costService.deleteCostExtra(costExtra.id)
                .pipe(
                  map(() => CostExtraActions.deleteCostExtraCompleted({ costExtra })),
                  catchError(error => of(CostExtraActions.deleteCostExtraFailed({ error })))
                )
            )
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.deleteCostExtraCompleted),
      tap(({ costExtra }) => this.alertService.showConfirmMessage(`Attività ${costExtra.id} eliminata con successo`)),
      map(() => CostExtraActions.closeCostExtraDialog({})),
    )
  );



  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.saveCostExtraCompleted),
      map(action => action.costExtra),
      tap(costExtra => this.alertService.showConfirmMessage(`${costExtra.id} salvato con successo`)),
      map(() => CostExtraActions.closeCostExtraDialog({}))
    )
  );

  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.closeCostExtraDialog),
      withLatestFrom(this.store$.select(CostExtraSelectors.getCostExtraDialogId)),
      tap((param) => console.log(param)),
      map(([{ dialogId }, withLatestFrom]) => dialogId || withLatestFrom),
      tap((id) => { this.dialog.getDialogById(id)?.close(); })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.saveCostExtraCompleted),
      withLatestFrom(this.store$.select(CostExtraSelectors.getCostExtraTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => CostExtraActions.loadCostExtras({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.deleteCostExtraCompleted),
      withLatestFrom(this.store$.select(CostExtraSelectors.getCostExtraTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => CostExtraActions.loadCostExtras({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  selectCostExtra$ = createEffect(() => this.actions$.pipe(
    ofType(CostExtraActions.selectCostExtra),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(CostSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return CostExtraActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  costExtrasSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.costExtraSelected),
      map(() => CostExtraActions.closeSelectionDialog())
    ),
  )

  generateBillingLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.generateBillingLine),
      switchMap(({ costExtra }) =>
        this.alertService.showConfirmDialog("Generazione rata", `Sei sicuro di voler generare la rata per ${costExtra.article?.name}?`)
          .pipe(
            filter(confirm => confirm),
            switchMap(() =>
              this.costService.generateBillingLine(costExtra.id)
                .pipe(
                  map((result) => CostExtraActions.generateBillingLineCompleted({ billingLine: result })),
                  catchError(error => of(CostExtraActions.generateBillingLineFailed({ error })))
                )
            )
          )
      )
    )
  );

  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(CostExtraSelectors.getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  activitiesSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.costExtraSelected),
      map(() => CostExtraActions.closeSelectionDialog())
    ),
  )

  onGenerateCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.generateBillingLineCompleted),
      tap(() => this.alertService.showConfirmMessage(`Riga di fatturazione generata con successo`)),
      withLatestFrom(this.store$.select(CostExtraSelectors.getCostExtraTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => CostExtraActions.loadCostExtras({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  loadBillingLinesAfterGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CostExtraActions.generateBillingLineCompleted),
      tap(() => this.alertService.showConfirmMessage(`Riga di fatturazione generata con successo`)),
      withLatestFrom(this.store$.select(BillingLineSelectors.getBillingLinesTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => BillingLineActions.loadBillingLines({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  // loadBillingLinesAfterGenerate = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CostExtraActions.generateBillingLineCompleted),
  //     withLatestFrom(this.store$.select(BillingLineSelectors.getBillingLinesTableState)),
  //     map(([_, { currentPage, perPage, direction, order, filters, includes }]) => BillingLineActions.loadBillingLines({ page: currentPage, perPage, order, direction, filters, includes }))
  //   )
  // );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private costService: LaravelCostExtraService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }

}
