import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Employee } from 'src/app/_models/employee';
import { Subject } from 'rxjs';

import { EmployeeSelectionDialogComponent } from '../employee-selection-dialog/employee-selection-dialog.component';
import { Org } from 'src/app/_models/org';

export const EMPLOYEE_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => EmployeeFieldComponent),
};

@Component({
  selector: "app-employee-field",
  templateUrl: "./employee-field.component.html",
  styleUrls: ["./employee-field.component.scss"],
  providers: [EMPLOYEE_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class EmployeeFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<Employee> {
  private unsubscribe$ = new Subject<void>();
  private _value: Employee;
  private _disabled = false;

  private _required = false;
  focused: boolean; //TODO
  controlType?: string = "employee-selection-field";
  autofilled?: boolean;
  employeeAriaDescribedBy?: string;

  @Input()
  org?: Org;

  get errorState(): boolean {
    return this.required && !this.value;
  }

  constructor(
    public dialog: MatDialog,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectEmployee();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectEmployee() {
    this.dialog.open(EmployeeSelectionDialogComponent, {
      disableClose: false,
      data: {
        org: this.org,
        multiple: false
      },
    }).afterClosed().subscribe((selectedEmployee: Employee) => {
      if (selectedEmployee) {
        this.value = selectedEmployee;
      }
    });
  }

  clearEmployee() {
    this.value = null;
  }

  set value(value: Employee) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): Employee {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: Employee): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
