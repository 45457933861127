import { createAction, props } from "@ngrx/store";
import { ActivityDTO } from "../_models/activity";
import { CostExtraDTO, CostExtraFilters } from "../_models/costExtra";
import { BillingLineDTO } from "../_models/billing-line.model";

export const addCostExtra = createAction('[CostExtra] Add CostExtra', props<{ costExtra: CostExtraDTO }>());
export const showCostExtra = createAction('[Activities] Show CostExtra', props<{ currentCostExtra: CostExtraDTO }>());

export const loadCurrentCostExtra = createAction('[CostExtra] Load current CostExtra',);
export const loadCurrentCostExtraCompleted = createAction('[CostExtra] Load current CostExtra completed', props<{ currentCostExtra: CostExtraDTO }>());
export const loadCurrentCostExtraFailed = createAction('[CostExtra] Load current CostExtra failed', props<{ error: any }>());

export const loadCostExtras = createAction('[CostExtra] Load CostExtra', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: Partial<CostExtraFilters>, includes?: string[] }>());
export const loadCostExtrasCompleted = createAction('[CostExtra] Load CostExtra Completed', props<{ costExtras: CostExtraDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: Partial<CostExtraFilters>, includes?: string[] }>());
export const loadCostExtrasFailed = createAction('[CostExtra] Load CostExtra Failed', props<{ error: any }>());

export const loadCostExtra = createAction('[CostExtra] Load  CostExtra', props<{ id: number }>());
export const loadCostExtraCompleted = createAction('[CostExtra] Load  CostExtra completed', props<{ costExtra: CostExtraDTO }>());
export const loadCostExtraFailed = createAction('[CostExtra] Load  CostExtra failed', props<{ error: any }>());

export const changePage = createAction('[CostExtra] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[CostExtra] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[CostExtra] Change filters', props<{ filters: Partial<CostExtraFilters> }>());

export const editCostExtra = createAction('[CostExtra] Edit CostExtra', props<{ costExtra: CostExtraDTO, activity: ActivityDTO }>());
export const CostExtraDialogOpened = createAction('[CostExtra] Detail dialog opened', props<{ dialogId: string }>());
export const closeCostExtraDialog = createAction('[CostExtra] Close detail dialog', props<{ dialogId?: string }>());

export const saveCostExtra = createAction('[CostExtra] Save CostExtra', props<{ costExtra: CostExtraDTO }>());
export const saveCostExtraCompleted = createAction('[CostExtra] Save CostExtra completed', props<{ costExtra: CostExtraDTO }>());
export const saveCostExtraFailed = createAction('[CostExtra] Save CostExtra failed', props<{ error: any }>());

export const deleteCostExtra = createAction('[CostExtra] Delete CostExtra', props<{ costExtra: CostExtraDTO }>());
export const deleteCostExtraCompleted = createAction('[CostExtra] Delete CostExtra completed', props<{ costExtra: CostExtraDTO }>());
export const deleteCostExtraCancelled = createAction('[CostExtra] Delete CostExtra cancelled');
export const deleteCostExtraFailed = createAction('[CostExtra] Delete CostExtra failed', props<{ error: any }>());

export const selectCostExtra = createAction('[CostExtra] Select CostExtra', props<{ filters?: CostExtraFilters }>());
export const selectionDialogOpened = createAction('[CostExtra] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[CostExtra] Close selection dialog');
export const costExtraSelected = createAction('[CostExtra] CostExtra selected', props<{ costExtra: CostExtraDTO }>());

export const generateBillingLine = createAction('[CostExtra] Generate BillingLine', props<{ costExtra: CostExtraDTO }>());
export const generateBillingLineCompleted = createAction('[CostExtra] Generate BillingLine completed', props<{ billingLine: BillingLineDTO }>());
export const generateBillingLineFailed = createAction('[CostExtra] Generate BillingLine failed', props<{ error: any }>());
