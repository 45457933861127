<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <div *ngIf="message" class="text-center" style="margin: 15px 0px;" [innerHTML]="message"></div>
  <div class="field_container">
    <mat-form-field>
      <mat-label>Da</mat-label>
      <input matInput [matDatepicker]="fromDatePicker" [formControl]="startDateCtrl" />
      <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>A</mat-label>
      <input matInput [matDatepicker]="toDatePicker" [formControl]="endDateCtrl" />
      <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="confirm()">Conferma</button>
  <button mat-raised-button (click)="cancel()">Annulla</button>
</div>