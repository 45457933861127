import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base';
import { Project } from './project';

export type ArticleArea = 'N' | 'S' | 'H' | 'Q' | 'I';
export const ARTICLE_AREAS: ArticleArea[] = ['N', 'S', 'H', 'Q', 'I'];

export interface ArticleFilters {
  name: string;
  area: ArticleArea;
  code: string;
  expense: boolean;
  project?: Project;
}

export interface ArticleDTO extends BaseDTO {
  name: string;
  code: string;
  area: ArticleArea;
  expense: boolean;
  note?: string;
}

export class Article extends Base {
  name: string;
  code: string;
  area: ArticleArea;
  expense: boolean;
  note?: string;
  constructor(dto: ArticleDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.name = dto.name;
      this.code = dto.code;
      this.area = dto.area;
      this.expense = dto.expense;
      this.note = dto.note;
    }
  }

  public toDTO(): ArticleDTO {
    const result: ArticleDTO = <ArticleDTO>super.toDTO();
    result.name = this.name;
    result.code = this.code;
    result.area = this.area;
    result.expense = this.expense;
    result.note = this.note;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Article): Article {
    const formModel = formGroup.value;

    let article: Article = new Article(null);

    article.name = formModel.name;
    article.code = formModel.code;
    article.area = formModel.area;
    article.expense = formModel.expense;
    article.note = formModel.note;

    if (original) {
      article.objectId = original.objectId;
      article.loadedRelations = original.loadedRelations;
    }
    return article;
  }

  public get stringRepresentation() {
    return this.name;
  }
}
