import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgObjectPipesModule } from 'angular-pipes';
import { NgxWigModule } from 'ngx-wig';

import { AppMaterialModule } from '../app-material/app-material.module';
import { ActivityFieldComponent } from './activity-field/activity-field.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { ActivitySelectionComponent } from './activity-selection/activity-selection.component';
import { ArticleFieldComponent } from './article-field/article-field.component';
import { ArticleFiltersComponent } from './article-filters/article-filters.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { ArticleSelectionComponent } from './article-selection/article-selection.component';
import { AttachmentListComponent } from './attachment-list/attachment-list.component';
import { AttachmentUploadComponent } from './attachment-upload/attachment-upload.component';
import { CourseStatusComponent } from './course-status/course-status.component';
import { DateMonthFieldComponent } from './date-month-field/date-month-field.component';
import { ShowForDirective } from './directives/show-for.directive';
import { DateRangeSelectionDialogComponent } from './date-range-selection-dialog/date-range-selection-dialog.component';
import { EmployeeSelectionDialogComponent } from './employee-selection-dialog/employee-selection-dialog.component';
import { FileSelectionDialogComponent } from './file-selection-dialog/file-selection-dialog.component';
import { LogoUploadComponent } from './logo-upload/logo-upload.component';
import { OrgFieldComponent } from './org-field/org-field.component';
import { OrgSelectionDialogComponent } from './org-selection-dialog/org-selection-dialog.component';
import { CurrencyLocalPipe } from './pipes/currency-local.pipe';
import { DefaultPipe } from './pipes/deault.pipe';
import { UserRolePipe } from './pipes/userRole.pipe';
import {
  PlannedCourseSelectionDialogComponent,
} from './planned-course-selection-dialog/planned-course-selection-dialog.component';
import { CoursesPipe } from './planned-unit-selection-dialog/courses.pipe';
import {
  PlannedUnitSelectionDialogComponent,
} from './planned-unit-selection-dialog/planned-unit-selection-dialog.component';
import { ProjectFieldComponent } from './project-field/project-field.component';
import { ProjectFiltersComponent } from './project-filters/project-filters.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectSelectionComponent } from './project-selection/project-selection.component';
import { RecoveryDetailsDialogComponent } from './recovery-details-dialog/recovery-details-dialog.component';
import { SendDialogMailComponent } from './send-dialog-email/send-dialog-email.component';
import { SendDialogComponent } from './send-dialog/send-dialog.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { UserFieldComponent } from './user-field/user-field.component';
import { UserSelectionDialogComponent } from './user-selection-dialog/user-selection-dialog.component';
import { UsersFieldComponent } from './users-field/users-field.component';
import { EmployeeFieldComponent } from './employee-field/employee-field.component';
import { CostListComponent } from './cost-list/cost-list.component';
import { CostSelectionComponent } from './cost-selection/cost-selection.component';
import { CostFieldComponent } from './cost-field/cost-field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgObjectPipesModule,
    NgxWigModule,
  ],
  declarations: [
    CurrencyLocalPipe,
    CoursesPipe,
    OrgSelectionDialogComponent,
    UserSelectionDialogComponent,
    EmployeeSelectionDialogComponent,
    PlannedCourseSelectionDialogComponent,
    PlannedUnitSelectionDialogComponent,
    LogoUploadComponent,
    AttachmentListComponent,
    AttachmentUploadComponent,
    CourseStatusComponent,
    DefaultPipe,
    SendDialogComponent,
    FileSelectionDialogComponent,
    OrgFieldComponent,
    EmployeeFieldComponent,
    UserFieldComponent,
    ProjectListComponent,
    ProjectFieldComponent,
    ProjectSelectionComponent,
    ProjectFiltersComponent,
    DateMonthFieldComponent,
    ActivityListComponent,
    UsersFieldComponent,
    TimePickerComponent,
    ArticleFiltersComponent,
    ArticleListComponent,
    ArticleSelectionComponent,
    ArticleFieldComponent,
    UserRolePipe,
    ActivityFieldComponent,
    ShowForDirective,
    ActivitySelectionComponent,
    SendDialogMailComponent,
    RecoveryDetailsDialogComponent,
    DateRangeSelectionDialogComponent,
    CostListComponent,
    CostSelectionComponent,
    CostFieldComponent
  ],
  entryComponents: [
    OrgSelectionDialogComponent,
    EmployeeSelectionDialogComponent,
    PlannedCourseSelectionDialogComponent,
    PlannedUnitSelectionDialogComponent,
    SendDialogComponent,
    FileSelectionDialogComponent,
    RecoveryDetailsDialogComponent,
    RecoveryDetailsDialogComponent,
    DateRangeSelectionDialogComponent
  ],
  exports: [
    CurrencyLocalPipe,
    ShowForDirective,
    OrgSelectionDialogComponent,
    UserSelectionDialogComponent,
    EmployeeSelectionDialogComponent,
    PlannedCourseSelectionDialogComponent,
    PlannedUnitSelectionDialogComponent,
    LogoUploadComponent,
    AttachmentListComponent,
    AttachmentUploadComponent,
    CourseStatusComponent,
    DefaultPipe,
    OrgFieldComponent,
    EmployeeFieldComponent,
    UserFieldComponent,
    ProjectFieldComponent,
    ProjectSelectionComponent,
    ProjectListComponent,
    ProjectFiltersComponent,
    AppMaterialModule,
    ReactiveFormsModule,
    CommonModule,
    DateMonthFieldComponent,
    ActivityListComponent,
    UsersFieldComponent,
    TimePickerComponent,
    ArticleListComponent,
    ArticleSelectionComponent,
    ArticleFieldComponent,
    UserRolePipe,
    ActivityFieldComponent,
    ActivitySelectionComponent,
    SendDialogMailComponent,
    RecoveryDetailsDialogComponent,
    DateRangeSelectionDialogComponent,
    CostListComponent,
    CostSelectionComponent,
    CostFieldComponent
  ],
})
export class SharedModule { }
