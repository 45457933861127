// Cost Extra Selectors

import { createSelector } from "@ngrx/store";
import { CostExtraState } from "./cost-extra.reducer";
import { selectCostExtraState } from 'src/app/_store';

export const getCostExtraTableState = createSelector(
  selectCostExtraState,
  (state: CostExtraState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getCostExtras = createSelector(
  selectCostExtraState,
  (state: CostExtraState) => state.list
);

export const getTotalCostExtras = createSelector(
  getCostExtraTableState,
  (state: CostExtraState) => state.total
);

export const getCostExtrasCurrentPage = createSelector(
  getCostExtraTableState,
  (state: CostExtraState) => state.currentPage
);

export const getCostExtrasPerPage = createSelector(
  getCostExtraTableState,
  (state: CostExtraState) => state.perPage
);

export const getCostExtrasOrder = createSelector(
  getCostExtraTableState,
  (state: CostExtraState) => state.order
);

export const getCostExtrasDirection = createSelector(
  getCostExtraTableState,
  (state: CostExtraState) => state.direction
);

export const getFilters = createSelector(
  getCostExtraTableState,
  (state: CostExtraState) => state.filters
);

export const getCostExtraDialogId = createSelector(
  selectCostExtraState,
  (state: CostExtraState) => state.dialogId
);

export const getSelectionDialogId = createSelector(
  selectCostExtraState,
  (state: CostExtraState) => state.selectionDialogId
);

export const getCurrentCostExtra = createSelector(
  selectCostExtraState,
  (state: CostExtraState) => state.currentCostExtra
);
export const getCostExtraId = createSelector(
  selectCostExtraState,
  (state: CostExtraState) => state.costExtraId
);
