import { Action, createReducer, on } from '@ngrx/store';
import { ActivityDTO, ActivityFilters } from 'src/app/_models/activity';

import * as ActivityActions from './activity.actions';

export interface ActivityState {
  list: ActivityDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: Partial<ActivityFilters>,
  dialogId: string,
  selectionDialogId: string,
  currentActivity: ActivityDTO,
  activityId: number,
  userIds: number[],
};

const initialState: ActivityState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  activityId: null,
  currentActivity: null,
  userIds: [],
};

const activityReducer = createReducer(
  initialState,
  on(ActivityActions.loadActivitiesCompleted, (state, { activities, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, list: activities, currentPage, total, perPage, order, direction, includes };
  }),
  on(ActivityActions.activityDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ActivityActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ActivityActions.loadActivities, (state, { filters }) => {
    return { ...state, filters };
  }),
  on(ActivityActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ActivityActions.userFilterDialogOpened, (state, { userDialogId }) => {
    return { ...state, userDialogId };
  }),
  on(ActivityActions.showActivity, (state, { currentActivity }) => {
    return { ...state, currentActivity };
  }),
  on(ActivityActions.addActivity, (state) => {
    return { ...state, currentActivity: null };
  }),
  on(ActivityActions.editActivity, (state, { activity }) => {
    return { ...state, currentActivity: activity };
  }),
  on(ActivityActions.saveActivityCompleted, (state, { activity }) => {
    if (state.currentActivity?.id == activity.id) {
      return { ...state, currentActivity: activity };
    }
    if (!state.currentActivity) {
      return { ...state, currentActivity: activity };
    }
    return { ...state };
  }),
  on(ActivityActions.loadCurrentActivityCompleted, (state, { currentActivity }) => {
    return { ...state, currentActivity };
  }),
  on(ActivityActions.loadActivityCompleted, (state, { activity }) => {
    return { ...state, currentActivity: activity };
  }),
  on(ActivityActions.filterByUsers, (state, { userIds }) => {
    return { ...state, userIds };
  }),

);

export function reducer(state: ActivityState | undefined, action: Action) {
  return activityReducer(state, action);
}
