import { AbstractControl } from '@angular/forms';

import { formatDateForBackend } from '../_helpers/utils';
import { environment } from './../../environments/environment';
import { Address, AddressDTO, Base, BaseDTO } from './base';
import { Deadline, DeadlineDTO, Employee } from './employee';
import { Participant, ParticipantDTO } from './planning';
import { OrgData } from './registration';
import { Service, ServiceDTO } from './service';


export type PaymentMethod = "Rimessa diretta" | "Bonifico bancario" | "RIBA";
export const PAYMENT_METHODS: PaymentMethod[] = ["Rimessa diretta", "Bonifico bancario", "RIBA"];
export const defaultPaymentMethod: PaymentMethod = "Bonifico bancario";

export type PaymentTime = 'Vista fattura' | '30 giorni' | '60 giorni' | '90 giorni' | '120 giorni';
export const PAYMENT_TIMES: PaymentTime[] = ['Vista fattura', '30 giorni', '60 giorni', '90 giorni', '120 giorni'];
export const defaultPaymentTime: PaymentTime = "30 giorni";
export interface OrgDTO extends BaseDTO {
  name: string;
  VAT: string;
  phone?: string;
  cellphone?: string;
  email?: string;
  fax?: string;
  website?: string;
  skype?: string;
  PEC?: string;
  address?: AddressDTO;
  first_contact?: ContactDTO;
  warning_days?: number; //Default days before expiration
  note?: string;
  partner?: boolean;
  logo?: string;
  deadlines?: DeadlineDTO[];
  participations?: ParticipantDTO[];
  external_id?: string;
  payment_method: PaymentMethod;
  payment_time: PaymentTime;
  referent: string;
}

export class Org extends Base {
  name: string;
  VAT: string;
  phone?: string;
  cellphone?: string;
  email?: string;
  fax?: string;
  website?: string;
  skype?: string;
  PEC?: string;
  address?: Address;
  firstContact?: Contact;
  warningDays?: number; //Default days before expiration
  note?: string;
  deadlines?: Deadline[];
  partner?: boolean;
  logoFile?: File;
  externalId?: string;
  participations?: Participant[];
  private _logo?: string;
  employees?: Employee[];
  paymentMethod: PaymentMethod;
  paymentTime: PaymentTime;
  referent: string;

  constructor(dto: OrgDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.name = dto.name;
      this.VAT = dto.VAT;
      this.phone = dto.phone;
      this.cellphone = dto.cellphone;
      this.email = dto.email;
      this.fax = dto.fax;
      this.website = dto.website;
      this.skype = dto.skype;
      this.PEC = dto.PEC;
      if (dto.address) {
        this.address = new Address(dto.address);
      }
      if (dto.first_contact) {
        this.firstContact = new Contact(dto.first_contact);
      }
      if (dto.participations) {
        this.participations = dto.participations.map(participation => new Participant(participation));
        this.addLoadedRelation("participations");
      }
      this.partner = dto.partner;
      this.logo = dto.logo;
      this.warningDays = dto.warning_days;
      this.note = dto.note;
      this.externalId = dto.external_id;
      this.paymentMethod = dto.payment_method;
      this.paymentTime = dto.payment_time;
      this.referent = dto.referent;
    }
  }

  public toDTO(): OrgDTO {
    let result: OrgDTO = <OrgDTO>super.toDTO();
    Object.assign(result, {
      name: this.name,
      VAT: this.VAT,
      phone: this.phone,
      cellphone: this.cellphone,
      email: this.email,
      fax: this.fax,
      website: this.website,
      skype: this.skype,
      PEC: this.PEC,
      address: this.address,
      first_contact: this.firstContact,
      warning_days: this.warningDays,
      partner: this.partner,
      note: this.note,
      external_id: this.externalId,
      payment_method: this.paymentMethod,
      payment_time: this.paymentTime,
      referent: this.referent
    });
    return result;
  }


  get logo(): string {
    return this._logo
      ? environment["laravel"]["serverUrl"] + "/" + this._logo
      : null;
  }

  set logo(logo: string) {
    this._logo = logo;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Org): Org {
    const formModel = formGroup.value;

    let org: Org = new Org(null);

    org.name = formModel.name;
    org.VAT = formModel.VAT;
    org.phone = formModel.phone;
    org.cellphone = formModel.cellphone;
    org.email = formModel.email;
    org.fax = formModel.fax;
    org.website = formModel.website;
    org.skype = formModel.skype;
    org.PEC = formModel.PEC;
    org.address = formModel.address;
    org.firstContact = formModel.firstContact;
    org.warningDays = formModel.warningDays;
    org.partner = formModel.partner;
    org.logoFile = formModel.logoFile;
    org.note = formModel.note;
    org.externalId = formModel.externalId;
    org.paymentMethod = formModel.paymentMethod;
    org.paymentTime = formModel.paymentTime;
    org.referent = formModel.referent;

    if (original) {
      org.objectId = original.objectId;
      org.loadedRelations = original.loadedRelations;
    }
    return org;
  }

  static fromRegistration(orgData: OrgData): Org {
    let org: Org = new Org(null);
    org.name = orgData.name;
    org.address = orgData.address;
    org.VAT = this.sanificateVat(orgData.VAT);
    org.email = orgData.email;
    org.phone = orgData.phone;

    return org;
  }

  get archived(): boolean {
    return this.deletedAt != null;
  }

  get contact(): string {
    return this.firstContact && this.firstContact.email ? this.firstContact.email : this.email;
  }

  static sanificateVat(vat) {
    vat = vat.replace('sm', '').replace('SM', '').replace('it', '').replace('IT', '').trim();
    if (vat.length != 11) {
      let regex = new RegExp("^0+(?!$)", 'g');
      vat = vat.replaceAll(regex, '')
    }
    return vat;
  }

  public get stringRepresentation() {
    return this.name;
  }
}

export interface ContactDTO {
  name: string;
  surname: string;
  phone?: string;
  email?: string;
  note?: string;
}

export class Contact {
  name: string;
  surname: string;
  phone?: string;
  email?: string;
  note?: string;

  constructor(dto: ContactDTO) {
    if (dto) {
      this.name = dto.name;
      this.surname = dto.surname;
      this.phone = dto.phone;
      this.email = dto.email;
      this.note = dto.note;
    }
  }
}

export interface OrderDTO extends BaseDTO {
  org?: OrgDTO;
  org_id?: number;
  service?: ServiceDTO;
  service_id?: number;
  execution_date?: Date;
  expiration_date?: Date;
  remind_date?: Date;
  price?: number;
  note?: string;
}

export class Order extends Base {
  org: Org;
  service: Service;
  executionDate?: Date;
  expirationDate?: Date;
  remindDate?: Date;
  price?: number;
  note?: string;

  constructor(dto: OrderDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.price = dto.price;
      if (dto.execution_date) {
        this.executionDate = new Date(dto.execution_date);
      }
      if (dto.expiration_date) {
        this.expirationDate = new Date(dto.expiration_date);
      }
      if (dto.remind_date) {
        this.remindDate = new Date(dto.execution_date);
      }
      if (dto.service) {
        this.service = new Service(dto.service);
        this.addLoadedRelation("service");
      }
      if (dto.org) {
        this.org = new Org(dto.org);
        this.addLoadedRelation("org");
      }
      this.note = dto.note;
    }
  }

  public toDTO(): OrderDTO {
    let result: OrderDTO = <OrderDTO>super.toDTO();
    Object.assign(result, {
      org_id: this.org ? this.org.objectId : null,
      service_id: this.service ? this.service.objectId : null,
      execution_date: formatDateForBackend(this.executionDate),
      expiration_date: formatDateForBackend(this.expirationDate),
      remind_date: formatDateForBackend(this.remindDate),
      price: this.price,
      note: this.note
    });
    return result;
  }
}

export interface OrgsFilter {
  queryString?: string;
  onlyPartners?: boolean;
  includeArchived?: boolean;
}
