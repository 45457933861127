<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona costo
  </span>
</header>
<mat-dialog-content>
  <app-cost-list [costs$]="costs" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectCost)="selectCost($event)"></app-cost-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>