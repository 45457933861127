import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { CostExtra, CostExtraFilters } from "src/app/_models/costExtra";
import { State } from "src/app/_store/";

import * as CostActions from 'src/app/_store/cost-extra.actions';

export const COST_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => CostFieldComponent),
};

@Component({
  selector: "app-cost-field",
  templateUrl: "./cost-field.component.html",
  styleUrls: ["./cost-field.component.scss"],
  providers: [COST_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class CostFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<CostExtra> {
  private unsubscribe$ = new Subject<void>();
  private _value: CostExtra;
  private _disabled = false;

  private _required = false;
  errorState: boolean; //TODO
  focused: boolean; //TODO
  controlType?: string = "cost-selection-field";
  autofilled?: boolean;
  costAriaDescribedBy?: string;


  @Input()
  costFilters: CostExtraFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.actions$
      .pipe(
        ofType(CostActions.costExtraSelected),
        takeUntil(this.unsubscribe$),
        map(({ costExtra }) => (costExtra ? new CostExtra(costExtra) : null))
      )
      .subscribe((cost) => (this.value = cost));
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectCost();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectCost() {
    this.store$.dispatch(CostActions.selectCostExtra({ filters: this.costFilters }));
  }

  clearCost() {
    this.value = null;
  }

  set value(value: CostExtra) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): CostExtra {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: CostExtra): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
