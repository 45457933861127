<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  {{confirmMessage}}
  <div *ngIf="checkMessage" class="text-center" style="margin: 15px 0px;">
    <mat-checkbox [(ngModel)]="confirmCheck">{{checkMessage}}</mat-checkbox>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!confirmCheck"
    (click)="dialogRef.close(true)">{{confirmButtonLabel}}</button>
  <button mat-raised-button (click)="dialogRef.close(false)">{{cancelButtonLabel}}</button>
</div>