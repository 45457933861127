<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="costExtra">Modifica Costo: {{costExtra.objectId}}</span>
    <span *ngIf="!costExtra">Nuovo Costo</span>
  </span>
  <span *ngIf="costExtra">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteCost()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="costExtraForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width" style="height: 70px;">
        <mat-label>Articolo</mat-label>
        <app-article-field formControlName="article" [articleFilters]="articleFilters"
          [showExpenseFilter]="false"></app-article-field>
      </mat-form-field>
      <mat-slide-toggle matInput formControlName="isInvoice" labelPosition="after">
        Da fatturare
      </mat-slide-toggle>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="full-width-input">
        <mat-label>Quantità</mat-label>
        <input matInput formControlName="quantity">
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width-input">
        <mat-label>Costo Singolo</mat-label>
        <input matInput formControlName="singleCost">
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <div class="full-width-input">
        <label>Costo Totale: </label>
        <span> {{costTotal | currency: "EUR"}}</span>
        <!-- <span *ngIf="costExtra"> {{costExtra.costTotal}} </span> -->
      </div>
    </div>



    <div class="u-flex-full">
      <mat-form-field class="full-width-input">
        <mat-label>Note</mat-label>
        <textarea matInput formControlName="note"></textarea>
      </mat-form-field>
    </div>

  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="costExtraForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="costExtraForm.pristine || !costExtraForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>