import { Org } from 'src/app/_models/org';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { BillingLineFilters } from 'src/app/_models/billing-line.model';


@Component({
  selector: 'app-billing-line-filters',
  templateUrl: './billing-line-filters.component.html',
  styleUrls: ['./billing-line-filters.component.scss']
})
export class BillingLineFiltersComponent {
  @Output()
  onFilter: EventEmitter<Partial<BillingLineFilters>> = new EventEmitter();

  private _defaultFilters: Partial<BillingLineFilters>;
  filtersForm = this.fb.group({
    org: new FormControl<Org>(null),
    from: new FormControl<Date>(null),
    to: new FormControl<Date>(null),
    identifier: new FormControl<number>(null),
    toBill: new FormControl<boolean>(null),
    hasInvoice: new FormControl<boolean>(null),

  });

  constructor(private fb: FormBuilder) { }

  applyFilters() {
    this.onFilter.emit(this._getBillingLineFilters());
  }

  private _getBillingLineFilters(): Partial<BillingLineFilters> {
    let filters: Partial<BillingLineFilters> = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.org = values.org;
      filters.from = values.from;
      filters.to = values.to;
      filters.identifier = values.identifier;
      filters.toBill = values.toBill;
      filters.hasInvoice = values.hasInvoice;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): Partial<BillingLineFilters> {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: Partial<BillingLineFilters>) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          org: this.defaultFilters.org,
          from: this.defaultFilters.from,
          to: this.defaultFilters.to,
          identifier: this.defaultFilters.identifier,
          toBill: this.defaultFilters.toBill,
          hasInvoice: this.defaultFilters.hasInvoice
        })
      }
    }
  }
}
