import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PAGE_SIZE_OPTIONS } from 'src/app/_helpers/table.helper';
import { State } from 'src/app/_store/';
import * as CostActions from 'src/app/_store/cost-extra.actions';
import * as CostSelectors from 'src/app/_store/cost-extra.selectors';

import { CostExtraColumn } from '../cost-list/cost-list.component';
import { CostExtra, CostExtraFilters } from 'src/app/_models/costExtra';


@Component({
  selector: 'app-cost-selection',
  templateUrl: './cost-selection.component.html',
  styleUrls: ['./cost-selection.component.scss']
})
export class CostSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  costs: Observable<CostExtra[]>;
  filters: Observable<Partial<CostExtraFilters>>;

  @Input()
  defaultFilters: CostExtraFilters;

  displayedColumns: CostExtraColumn[] = ["id", "article", "quantity", "single_cost", "total", "is_invoice", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<State>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.costs = this.store$.pipe(select(CostSelectors.getCostExtras), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new CostExtra(dto)) : null));
    this.total = this.store$.pipe(select(CostSelectors.getTotalCostExtras), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(CostSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(CostActions.loadCostExtras(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters, includes: ['article'] }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(CostActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(CostActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: CostExtraFilters) {
    this.store$.dispatch(CostActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCost(cost: CostExtra) {
    if (cost) {
      this.store$.dispatch(CostActions.costExtraSelected({ costExtra: cost.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(CostActions.closeSelectionDialog())
  }
}
