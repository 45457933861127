export const baseEditorConfiguration: CKEDITOR.config = {
  allowedContent: true,
  // autoParagraph: false,
  // versionCheck: false,
  removePlugins: "elementspath",
  extraPlugins: "removeformat",
  toolbarGroups: [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
    },
    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },
    "/",
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },
  ],

  removeButtons:
    "Save,NewPage,Preview,Print,Templates,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Link,Flash,Unlink,Image,Anchor,Table,HorizontalRule,Smiley,PageBreak,Iframe,Format,Styles,TextColor,Maximize,About,ShowBlocks,BGColor,Font,FontSize",
};

export interface MailTemplate {
  name: string;
  content: string;
  subject?: string;
  type: "Course" | "PlannedUnit";
}

export const TEMPLATES: MailTemplate[] = [
  {
    name: "Corso pianificato",
    content: `Gentilissimi,<br/>
        vi ricordiamo di stampare e archiviare quanto in allegato alla presente e di consegnarne una copia al lavoratore $PERSON_NAME$ $PERSON_SURNAME$.<br/>
        Vi ringraziamo per avere scelto GEM BB S.r.l.: prima della scadenza del corso frequentato vi invieremo un promemoria per venire da noi ad aggiornarlo.<br/>
        Se lo ritenete opportuno, vi chiediamo di trovare un minuto per lasciare una recensione pubblica su Google a proposito della qualità del servizio ricevuto.<br/>
        Per altre esigenze formative potete verificare i prossimi corsi calendarizzati su <a href="https://www.gembb.sm/formazione/">https://www.gembb.sm/formazione/</a> , mentre per corsi da organizzare presso la vostra azienda potete chiamare il numero 0549.960151.<br/>
        <br/>
        A disposizione auguriamo buon lavoro,<br/>
        <br/>
        <b>GEM BB S.r.l.</b><br/>`,
    type: "Course",
  },
  {
    name: "Conferma corso",
    subject: "Comunicazione in merito al corso $TITOLO_CORSO$ del $DATE$ orario $START_TIME$ presso $COURSE_LOCATION$",
    content: `Gentilissimo referente aziendale,<br/>
la presente comunicazione riguarda il partecipante iscritto al corso in oggetto <b>corso $TITOLO_CORSO$ Unità formativa del $DATE$ orario $START_TIME$ presso $COURSE_LOCATION$</b>
<br/><br/>
Comunichiamo che il corso è confermato  con la seguente organizzazione:<br/>
Registrazione partecipanti: 10 minuti prima<br/>
Lezione teorica giorno data ora presso<br/>
Lezione pratica giorno data ora presso<br/>
Indicazione di parcheggio<br/>
<br/><br/>
La parte pratica è <b>all'aperto</b> si consigliano abiti idonei alla stagione e si richiede di indossare i DPI personali (SCARPE ANTINFORTUNISTICHE, casco, guanti, imbragature in base al corso ecc).<br/><br/>
In caso di forte mal tempo la parte pratica sarà posticipata a data da destinarsi.<br/><br/>
<b>SI CHIEDE di avvisare il partecipante per tempo  e di garantire la puntualità.</b><br/><br/>
<b>All’ingresso del corso di Formazione verrà verificata l’identità del partecipante, potete presentare carta di identità valida, patente o passaporto</b><br/><br/>
<em>RICORDIAMO LE CLAUSOLE DI ACQUISTO</em><br/><br/>
Per <b>disdire l’iscrizione</b> il partecipante o l’azienda del partecipante deve inviare comunicazione scritta tramite E-mail a <a href="mailto:miriam.fabbri@gembb.sm">miriam.fabbri@gembb.sm</a> <b>almeno 5 giorni lavorativi prima dell’inizio del corso:</b> in tal caso sarà restituita l’intera somma già versata oppure sarà possibile sostituire l’iscritto con un’altra persona, senza addebito di alcuna spesa aggiuntiva.<br/><br/>
In caso di <b>assenza</b> (non comunicata come specificato al capoverso precedente), <b>l’iscrizione al corso è fatturata e dovrà essere saldata</b>: è data opportunità al partecipante di un’unica e sola possibilità di recupero nel primo corso successivamente calendarizzato che viene avviato. Persa tale occasione, il partecipante sarà tenuto a una nuova iscrizione e relativo pagamento.<br/><br/>
Se l’<b>assenza</b> è relativa ad un corso che prevede una <b>parte pratica</b>, il recupero sarà possibile solo con il pagamento di un <b>importo pari al 50%</b> del valore del biglietto iniziale.<br/><br/>
RICORDIAMO DI SALDARE LA QUOTA DI PARTECIPAZIONE SE NON ANCORA EFFETTUATO IL BONIFICO . In caso di pagamento effettuato prendete nota solo di quanto sopra.<br/><br/>

<em>Dati GEM BB S.r.l. per il bonifico: Banca Agricola Commerciale Ag. Borgo Maggiore IBAN: SM 97 P 03034 09804 000040117507   INDICARE IL NUMERO DI ORDINE DEL CORSO  - oppure tipo corso data e nome del partecipante</em><br/><br/>
<br/>
Questa è una mail automatica
<br/><br/>
Cordiali saluti,<br/>
<b>GEM BB S.r.l</b>.<br/>
0549 - 960151`,
    type: "PlannedUnit",
  }
];
