import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ListResultDTO } from "src/app/_helpers/listResult.interface";
import { CostExtraDTO, CostExtraFilters } from "src/app/_models/costExtra";
import { environment } from '../../../environments/environment';
import { BillingLineDTO } from "src/app/_models/billing-line.model";

@Injectable({ providedIn: "root" })
export class LaravelCostExtraService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/cost_extra",
      list: environment["laravel"]["serverUrl"] + "/api/cost_extras",
      store: environment["laravel"]["serverUrl"] + "/api/cost_extra",
      delete: environment["laravel"]["serverUrl"] + "/api/cost_extra",
      generateBillingLine: environment["laravel"]["serverUrl"] + "/api/cost_extra/billing_line/generate",
    };
  }

  getCostExtraById(
    id: number,
    include?: string[]
  ): Observable<CostExtraDTO> {
    let params = {
      id: "" + id,
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<CostExtraDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: Partial<CostExtraFilters>,
    includes?: string[]
  ): Observable<ListResultDTO<CostExtraDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.search) params["search"] = filters.search;
      if (filters.activityId) params["activity_id"] = filters.activityId;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<CostExtraDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<CostExtraDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public generateBillingLine(cost_id: number): Observable<BillingLineDTO> {
    return this.httpClient.post<BillingLineDTO>(this.ROUTES.generateBillingLine, {
      cost_id
    });
  }


  public upsertCostExtra(dto_cost: CostExtraDTO): Observable<CostExtraDTO> {
    return dto_cost.id ?
      this.httpClient.put<CostExtraDTO>(this.ROUTES.store, {
        cost_extra: dto_cost,
      }) : this.httpClient.post<CostExtraDTO>(this.ROUTES.store, {
        cost_extra: dto_cost,
      })
  }

  public deleteCostExtra(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

}
