<header mat-dialog-title>
  <span *ngIf="org">{{org.name}} - </span> Scegli persona
</header>
<mat-dialog-content style="width: 60vw">
  <div class="mat-elevation-z2">
    <mat-toolbar>
      <form (onSubmit)="reload()" novalidate (keyup.enter)="reload()" style="width: 100%">
        <div style="display: flex;
          align-items: center;
          justify-content: flex-end">
          <mat-form-field floatLabel="never">
            <input matInput type="text" placeholder="Cerca" [(ngModel)]="queryString" name="queryString" />
          </mat-form-field>
          <button type="button" mat-icon-button color="primary">
            <mat-icon aria-label="Start search button" (click)="reload()">search</mat-icon>
          </button>
          <button type="button" mat-icon-button color="primary" *ngIf="queryString">
            <mat-icon aria-label="Clear search button" (click)="queryString = null; reload()">close</mat-icon>
          </button>
          <mat-checkbox *ngIf="!org" [(ngModel)]="onlyPrivate" (change)="reload()" name="onlyPrivate"
            matTooltip="Escludi i dipendenti di Aziende">Solo privati
          </mat-checkbox>
        </div>
      </form>
    </mat-toolbar>

    <div *ngIf="!(dataSource.loading$ | async) && (dataSource.total$ | async) == 0" layout-fill layout="column">
      <mat-card class="text-center" layout="row" layout-align="center">
        <h4>Nessun nominativo presente</h4>
      </mat-card>
    </div>
    <div [hidden]="(dataSource.loading$ | async) || (dataSource.total$ | async) == 0">
      <mat-table #table [dataSource]="dataSource" class="table" matSort matSortActive="created_at" matSortDisableClear
        matSortDirection="asc">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && (allSelected | async)"
              [indeterminate]="selection.hasValue() && (allSelected | async)">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" matTooltip="Persona già iscritta al corso"
              [matTooltipDisabled]="canSelect(row)" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [disabled]="!canSelect(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header="name">Nome</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.foreign && !row.italianSpeak"
              matTooltip="Attenzione! Straniero senza test di lingua italiana valido">
              <mat-icon>announcement</mat-icon>
            </span>
            {{ row.name }}
          </mat-cell>
        </ng-container>

        <!-- Surname Column -->
        <ng-container matColumnDef="surname">
          <mat-header-cell *matHeaderCellDef mat-sort-header="surname">Cognome</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.surname }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-raised-button color="primary" (click)="selectEmployee(row)">Seleziona</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-paginator [length]="(dataSource.total$ | async)" [pageSize]="25" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="multiple" [disabled]="!(selection?.selected?.length > 0)" mat-raised-button color="primary"
    (click)="confirmMultiple()">Conferma</button>
  <button mat-raised-button color="basic" (click)="close()">Chiudi</button>
</mat-dialog-actions>