import { AbstractControl } from "@angular/forms";
import { Activity, ActivityDTO } from "./activity";
import { Article, ArticleDTO } from "./article";
import { Base, BaseDTO } from "./base";
import { BillingLine, BillingLineDTO } from "./billing-line.model";

export interface CostExtraFilters {
  search: string;
  activityId: number;
}

export interface CostExtraDTO extends BaseDTO {
  article_id: number;
  activity_id: number;
  quantity: number;
  single_cost: number;
  note: string;
  is_invoice: boolean;

  article: ArticleDTO;
  activity: ActivityDTO;

  billing_lines: BillingLineDTO[];
}


export class CostExtra extends Base {
  articleId: number;
  activityId: number;
  quantity: number;
  singleCost: number;
  note: string;
  isInvoice: boolean;

  article: Article;
  activity: Activity;

  billingLines: BillingLine[];

  constructor(dto: CostExtraDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      if (dto.activity) {
        this.activity = new Activity(dto.activity);
        this.addLoadedRelation("activity");
      }
      if (dto.article) {
        this.article = new Article(dto.article);
        this.addLoadedRelation("article");
      }

      if (dto.billing_lines) {
        this.billingLines = dto.billing_lines.map(b => new BillingLine(b));
        this.addLoadedRelation("billing_lines");
      }

      this.articleId = dto.article_id;
      this.activityId = dto.activity_id;
      this.quantity = dto.quantity;
      this.note = dto.note;
      this.singleCost = dto.single_cost;
      this.isInvoice = dto.is_invoice;
    }
  }


  public toDTO(): CostExtraDTO {
    const result: CostExtraDTO = <CostExtraDTO>super.toDTO()
    result.article = this.article ? this.article.toDTO() : null;
    result.article_id = this.article?.objectId;
    result.activity = this.activity ? this.activity.toDTO() : null;
    result.activity_id = this.activity?.objectId;
    result.quantity = this.quantity;
    result.single_cost = this.singleCost;
    result.is_invoice = this.isInvoice;
    result.note = this.note;
    result.billing_lines = this.billingLines ? this.billingLines.map(b => b.toDTO()) : null;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: CostExtra): CostExtra {
    const formModel = formGroup.getRawValue();

    let costExtra: CostExtra = new CostExtra(null);
    costExtra.quantity = formModel.quantity;
    costExtra.singleCost = formModel.singleCost;
    costExtra.isInvoice = formModel.isInvoice ? true : false;
    costExtra.note = formModel.note;
    costExtra.activity = formModel.activity;
    costExtra.activityId = formModel.activity?.objectId;
    costExtra.article = formModel.article;
    costExtra.articleId = formModel.article?.objectId;

    if (original) {
      costExtra.objectId = original.objectId;
      costExtra.loadedRelations = original.loadedRelations;
    }

    return costExtra;
  }


  get costTotal() {
    return this.quantity * this.singleCost;
  }

  get stringRepresentation() {
    return `${this.article?.stringRepresentation} - ${this.costTotal}`
  }

}
