<header mat-dialog-title>
  Scegli utente
</header>
<mat-dialog-content style="width: 60vw">
  <div class="mat-elevation-z2">
    <mat-toolbar>
      <form (onSubmit)="reload()" novalidate (keyup.enter)="reload()">
        <span>
          <input matInput type="text" placeholder="Cerca" [(ngModel)]="queryString" name="queryString" />
          <button type="button" mat-icon-button color="primary">
            <mat-icon aria-label="Start search button" (click)="reload()">search</mat-icon>
          </button>
          <button type="button" mat-icon-button color="primary" *ngIf="queryString">
            <mat-icon aria-label="Clear search button" (click)="queryString = null; reload()">close</mat-icon>
          </button>
        </span>
      </form>
    </mat-toolbar>
    <div *ngIf="!(dataSource.loading$ | async) && (dataSource.total$ | async) == 0" layout-fill layout="column">
      <mat-card class="text-center" layout="row" layout-align="center">
        <h4>Nessuna azienda presente</h4>
      </mat-card>
    </div>
    <div [hidden]="(dataSource.loading$ | async) || (dataSource.total$ | async) == 0">
      <mat-table #table [dataSource]="dataSource" class="table" matSort matSortActive="created_at" matSortDisableClear
        matSortDirection="asc">

        <!-- Created Column -->
        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header="created_at" disableClear="true">
            Creato
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.createdAt | date : 'dd-MM-yyyy HH:mm:ss'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.email }}
            <mat-icon *ngIf="row.note" #tooltip="matTooltip" [matTooltip]="row.note">chat</mat-icon>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header="name">Nome</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name }}
          </mat-cell>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="surname">
          <mat-header-cell *matHeaderCellDef mat-sort-header="surname">Cognome</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.surname }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-raised-button color="primary" (click)="selectUser(row)">Seleziona</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-paginator [length]="(dataSource.total$ | async)" [pageSize]="25" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="basic" (click)="close()">Chiudi</button>
</mat-dialog-actions>