import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "date-range-selection-dialog",
  templateUrl: "./date-range-selection-dialog.component.html",
  styleUrls: ["./date-range-selection-dialog.component.scss"]
})
export class DateRangeSelectionDialogComponent {
  constructor(public dialogRef: MatDialogRef<DateRangeSelectionDialogComponent>) { }

  public message: string;
  public title: string;

  startDateCtrl: FormControl = new FormControl();
  endDateCtrl: FormControl = new FormControl();

  cancel() {
    this.dialogRef.close(false)
  }

  confirm() {
    this.dialogRef.close({
      start: this.startDateCtrl.value,
      end: this.endDateCtrl.value
    })
  }
}
