import { Activity, ActivityDTO } from './activity';
import { AbstractControl } from '@angular/forms';

import { formatDateForBackend } from '../_helpers/utils';
import { Article, ArticleDTO } from './article';
import { Base, BaseDTO } from './base';
import { BillingLine, BillingLineDTO } from './billing-line.model';
import { Org, OrgDTO } from './org';
import { User, UserDTO } from './user';
import { PlannedCourse, PlannedCourseDTO } from './planning';

export type ProjectArea = 'N' | 'S' | 'H' | 'Q';
export const PROJECT_AREAS: ProjectArea[] = ['N', 'S', 'H', 'Q'];

export type ProjectClass = 'Preventivo' | 'Consuntivo' | 'Canone';
export const PROJECT_CLASSES: ProjectClass[] = ['Preventivo', 'Consuntivo', 'Canone'];

export interface RecurringDetails {
  advance_billing_days: number,
  questions: number,
  hours: number
}

export interface ProjectFilters {
  client: OrgDTO;
  area: ProjectArea;
  offer_number: string;
  order_number: string;
  identifier: string;
  class: ProjectClass;
  open_from: Date;
  open_to: Date;
  include_closed: boolean;
  closed_from: Date;
  closed_to: Date;
  accountant: UserDTO;
}

export interface ProjectDTO extends BaseDTO {
  client?: OrgDTO;
  client_id?: number;
  accountant?: UserDTO;
  accountant_id?: number;
  planned_course?: PlannedCourseDTO;
  planned_course_id?: number;
  multiclient: boolean;
  area: ProjectArea;
  offer_number?: string;
  order_number?: string;
  order_date?: string;
  description?: string;
  progressive: number;
  identifier: string;
  class: ProjectClass;
  start_date: string;
  end_date?: string;
  delivery_requested_date: string;
  client_email: string;
  delivery_date?: string;
  note?: string;
  total?: number;
  hours?: number;
  hourly_rate?: number;
  daily_rate?: number;
  year_start?: number;
  period_start?: string;
  period_end?: string;
  recurring_details?: Partial<RecurringDetails>;
  billing_lines: BillingLineDTO[];
  activities: ActivityDTO[];
  article_id?: number;
  article?: ArticleDTO;
  consumed_questions: number;
}

export class Project extends Base {
  client?: Org;
  accountant?: User;
  plannedCourse: PlannedCourse;
  multiclient: boolean;
  area: ProjectArea;
  offerNumber?: string;
  orderNumber?: string;
  orderDate?: Date;
  description?: string;
  progressive: number;
  identifier: string;
  class: ProjectClass;
  startDate: Date;
  endDate?: Date;
  deliveryRequestedDate: Date;
  clientEmail: string;
  deliveryDate?: Date;
  note?: string;
  total?: number;
  hours?: number;
  hourlyRate?: number;
  dailyRate?: number;
  yearStart?: number;
  periodStart?: Date;
  periodEnd?: Date;
  recurringDetails?: Partial<RecurringDetails>
  billingLines: BillingLine[];
  activities: Activity[];
  articleId?: number;
  article?: Article;
  consumedQuestions: number;

  constructor(dto: ProjectDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      if (dto.client) {
        this.client = new Org(dto.client);
        this.addLoadedRelation("client");
      }
      if (dto.planned_course) {
        this.plannedCourse = new PlannedCourse(dto.planned_course);
        this.addLoadedRelation("planned_course");
      }
      this.multiclient = dto.multiclient;
      this.area = dto.area;
      this.offerNumber = dto.offer_number;
      this.orderNumber = dto.order_number;
      this.orderDate = dto.order_date ? new Date(dto.order_date) : null;
      this.description = dto.description;
      this.progressive = dto.progressive;
      this.identifier = dto.identifier;
      this.class = dto.class;
      this.startDate = dto.start_date ? new Date(dto.start_date) : null;
      this.endDate = dto.end_date ? new Date(dto.end_date) : null;
      this.deliveryDate = dto.delivery_date ? new Date(dto.delivery_date) : null;
      this.deliveryRequestedDate = dto.delivery_requested_date ? new Date(dto.delivery_requested_date) : null;
      this.clientEmail = dto.client_email;
      if (dto.accountant) {
        this.accountant = new User(dto.accountant);
        this.addLoadedRelation("accountant");
      }
      this.note = dto.note;
      this.total = dto.total;
      this.hours = dto.hours;
      this.hourlyRate = dto.hourly_rate;
      this.dailyRate = dto.daily_rate;
      this.yearStart = dto.year_start;
      this.periodStart = dto.period_start ? new Date(dto.period_start) : null;
      this.periodEnd = dto.period_end ? new Date(dto.period_end) : null;
      this.recurringDetails = dto.recurring_details;
      this.billingLines = dto.billing_lines?.map(dto => new BillingLine(dto));
      this.activities = dto.activities?.map(dto => new Activity(dto));
      this.addLoadedRelation("activities");
      this.article = dto.article ? new Article(dto.article) : null;
      this.articleId = dto.article_id;
      this.consumedQuestions = dto.consumed_questions;

    }
  }

  public toDTO(): ProjectDTO {
    const result: ProjectDTO = <ProjectDTO>super.toDTO();
    result.client = this.client ? this.client.toDTO() : null;
    result.client_id = this.client ? this.client.objectId : null;
    result.planned_course = this.plannedCourse ? this.plannedCourse.toDTO() : null;
    result.planned_course_id = this.plannedCourse ? this.plannedCourse.objectId : null;
    result.accountant = this.accountant ? this.accountant.toDTO() : null;
    result.accountant_id = this.accountant ? this.accountant.objectId : null;
    result.multiclient = this.multiclient;
    result.area = this.area;
    result.offer_number = this.offerNumber;
    result.order_number = this.orderNumber;
    result.order_date = formatDateForBackend(this.orderDate);
    result.description = this.description;
    result.progressive = this.progressive;
    result.identifier = this.identifier;
    result.class = this.class;
    result.start_date = formatDateForBackend(this.startDate);
    result.end_date = formatDateForBackend(this.endDate);
    result.delivery_requested_date = formatDateForBackend(this.deliveryRequestedDate);
    result.delivery_date = formatDateForBackend(this.deliveryDate);
    result.client_email = this.clientEmail;
    result.note = this.note;
    result.total = this.total;
    result.hours = this.hours;
    result.hourly_rate = this.hourlyRate;
    result.daily_rate = this.dailyRate;
    result.year_start = this.yearStart;
    result.period_start = formatDateForBackend(this.periodStart);
    result.period_end = formatDateForBackend(this.periodEnd);
    result.recurring_details = this.recurringDetails;
    result.article_id = this.articleId;
    result.consumed_questions = this.consumedQuestions;
    result.activities = this.activities
      ? this.activities.map((activity) => activity.toDTO())
      : null;
    result.billing_lines = this.billingLines
      ? this.billingLines.map((billingLine) => billingLine.toDTO())
      : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Project): Project {
    const formModel = formGroup.value;

    let project: Project = new Project(null);

    project.multiclient = formModel.multiclient;
    project.area = formModel.area;
    project.offerNumber = formModel.offer_number;
    project.orderNumber = formModel.order_number;
    project.orderDate = formModel.order_date;
    project.description = formModel.description;
    project.progressive = formModel.progressive;
    project.class = formModel.class;
    project.startDate = formModel.start_date;
    project.endDate = formModel.end_date;
    project.deliveryDate = formModel.delivery_date;
    project.deliveryRequestedDate = formModel.delivery_requested_date;
    project.clientEmail = formModel.client_email;
    project.accountant = formModel.accountant;
    project.client = formModel.client;
    project.note = formModel.note;
    project.total = formModel.total;
    project.hours = formModel.hours;
    project.hourlyRate = formModel.hourly_rate;
    project.dailyRate = formModel.daily_rate;
    project.yearStart = formModel.year_start;
    project.periodStart = formModel.period_start;
    project.periodEnd = formModel.period_end;
    project.recurringDetails = formModel.recurring_details;
    project.article = formModel.article;
    project.articleId = formModel.article?.objectId;
    project.consumedQuestions = formModel.consumed_questions;
    project.activities = formModel.activities;
    project.billingLines = formModel.billingLines

    if (original) {
      project.objectId = original.objectId;
      project.loadedRelations = original.loadedRelations;
    }
    return project;
  }

  get closed(): boolean {
    return !!this.endDate;
  }
  get totalBilled(): number {
    let totalBilled: number = 0;
    this.billingLines?.forEach((billingLine: BillingLine) => {
      totalBilled += billingLine.amount
    });
    return totalBilled;
  }

  get totalQuestions(): number {
    return this.recurringDetails?.questions || 0;
  }
}
