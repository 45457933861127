import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ListResultDTO } from '../../_helpers/listResult.interface';
import { formatDateForBackend } from '../../_helpers/utils';
import { ProjectArea, ProjectDTO, ProjectFilters } from '../../_models/project';

@Injectable({ providedIn: "root" })
export class LaravelProjectService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment["laravel"]["serverUrl"] + "/api/project",
      list: environment["laravel"]["serverUrl"] + "/api/projects",
      store: environment["laravel"]["serverUrl"] + "/api/project",
      delete: environment["laravel"]["serverUrl"] + "/api/project",
      restore:
        environment["laravel"]["serverUrl"] + "/api/project/restore",
      next_progressive: environment["laravel"]["serverUrl"] + "/api/project/next_progressive",
      deliver: environment["laravel"]["serverUrl"] + '/api/project/deliver'
    };
  }

  getProjectById(
    id: number,
    include?: string[]
  ): Observable<ProjectDTO> {
    let params = {
      id: "" + id,
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get<ProjectDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params,
      }),
    });
  }

  public getProjects(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    filters?: Partial<ProjectFilters>,
    include?: string[]
  ): Observable<ListResultDTO<ProjectDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filters) {
      if (filters.client) params["client_id"] = filters.client.id;
      if (filters.area) params["area"] = filters.area;
      if (filters.offer_number) params["offer_number"] = '' + filters.offer_number;
      if (filters.order_number) params["order_number"] = '' + filters.order_number;
      if (filters.identifier) params["identifier"] = '' + filters.identifier;
      if (filters.class) params["class"] = filters.class;
      if (filters.open_from) params["open_from"] = formatDateForBackend(filters.open_from);
      if (filters.open_to) params["open_to"] = formatDateForBackend(filters.open_to);
      if (filters.closed_from) params["closed_from"] = formatDateForBackend(filters.closed_from);
      if (filters.closed_to) params["closed_to"] = formatDateForBackend(filters.closed_to);
      if (filters.include_closed) params["include_closed"] = "true";
      if (filters.accountant) params["accountant_id"] = filters.accountant.id;
    }

    if (per_page) {
      if (page) params["page"] = "" + page;
      if (per_page) params["per_page"] = "" + per_page;
      return this.httpClient.get<ListResultDTO<ProjectDTO>>(
        this.ROUTES.list,
        {
          params: new HttpParams({ fromObject: params }),
        }
      );
    } else {
      return this.httpClient
        .get<ProjectDTO[]>(this.ROUTES.list, {
          params: new HttpParams({ fromObject: params }),
        })
        .pipe(
          map((results) => {
            return {
              data: results,
              total: results.length,
            };
          })
        );
    }
  }

  public upsertProject(dto: ProjectDTO): Observable<ProjectDTO> {
    return dto.id ?
      this.httpClient.put<ProjectDTO>(this.ROUTES.store, {
        project: dto,
      }) : this.httpClient.post<ProjectDTO>(this.ROUTES.store, {
        project: dto,
      })
  }

  public deleteProject(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public restoreProject(id): Observable<ProjectDTO> {
    return this.httpClient.put<ProjectDTO>(this.ROUTES.restore, {
      id: id,
    });
  }

  public getNextProgressive(area: ProjectArea, project_id: number): Observable<number> {
    return this.httpClient
      .get<number>(this.ROUTES.next_progressive, {
        params: new HttpParams({
          fromObject: {
            area, project_id
          }
        }),
      })
  }
}
