import { getCurrentUser, State } from 'src/app/_store/';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { Participant } from './../../_models/planning';
import { User } from './../../_models/user';
import { AlertService } from './../../_services/alert.service';
import { MailService } from './../../_services/mail.service';
import { MailTemplate, TEMPLATES } from 'src/app/_helpers/editor.helper';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: "app-send-mail-dialog",
  templateUrl: "./send-dialog.component.html",
  styleUrls: ["./send-dialog.component.scss"],
})
export class SendDialogComponent implements OnInit {
  recipients: Participant[];

  mailForm: UntypedFormGroup;

  currentUser: User;

  hidePlaceholders: boolean;
  hideIncludeCertificate: boolean;
  plannedUnitId: number;
  selectedTemplate: MailTemplate;
  templates: MailTemplate[];

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<State>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SendDialogComponent>,
    public userService: UserService,
    public alertService: AlertService,
    public mailService: MailService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store.select(getCurrentUser).subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
    this.createForm();
    if (this.data) {
      this.hidePlaceholders = !!this.data.hidePlaceholders;
      this.hideIncludeCertificate = !!this.data.hideIncludeCertificate;
      this.plannedUnitId = this.data.plannedUnitId;
      this.templates = TEMPLATES.filter((t) =>
        this.plannedUnitId ? t.type === "PlannedUnit" : t.type === "Course"
      );
      this.selectedTemplate =
        this.templates.length > 0 ? this.templates[0] : null;
    }
  }

  noMailRecipients(): Participant[] {
    return this.recipients
      ? this.recipients.filter((e) => !e.employee.email)
      : [];
  }

  noCertificateRecipients(): Participant[] {
    return this.recipients
      ? this.recipients.filter((p) => {
        return !(p.hasCertificate || p.completed);
      })
      : [];
  }

  ngOnInit() {
    this.recipients = this.data.recipients;
    this.initForm();
  }

  createForm() {
    this.mailForm = this.fb.group({
      sender: ["", [Validators.required, Validators.email]],
      subject: ["", Validators.required],
      content: [this.selectedTemplate && this.selectedTemplate.content ? this.selectedTemplate.content : "", Validators.required],
      template: [this.selectedTemplate],
      senderInBcc: "",
      includeCertificates: "",
      includeParticipants: "",
    });
  }

  initForm() {
    let subject = `Comunicazione in merito al corso`;
    let content = '';
    if (this.selectedTemplate) {
      subject = this.selectedTemplate.subject;
      content = this.selectedTemplate.content;
    }
    if (this.data.subject) {
      subject = this.data.subject;
    }
    if (this.data.content) {
      content = this.data.content;
    }
    this.mailForm.patchValue({
      sender: this.currentUser.email,
      senderInBcc: true,
      subject: subject,
      content: content,
      template: this.selectedTemplate
    });
  }

  onTemplateChange(templateContent: string) {
    this.mailForm.patchValue({
      content: templateContent,
    });
  }

  close() {
    this.dialogRef.close();
  }

  send() {
    const formModel = this.mailForm.value;
    this.mailService
      .sendMailToParticipants(
        this.recipients,
        this.plannedUnitId,
        formModel.content,
        formModel.subject,
        formModel.sender,
        formModel.senderInBcc,
        formModel.includeCertificates,
        formModel.includeParticipants
      )
      .subscribe(
        () => {
          this.alertService.showConfirmMessage(
            `E-mail inviata correttamente a ${this.recipients.length - this.noMailRecipients().length
            } persone`
          );
          this.close();
        },
        (error) => {
          this.alertService.showErrorMessage(`Errore nell'invio e-mail`, error);
        }
      );
  }
}
