import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ARTICLE_AREAS, ArticleArea, ArticleFilters } from 'src/app/_models/article';

@Component({
  selector: 'app-article-filters',
  templateUrl: './article-filters.component.html',
  styleUrls: ['./article-filters.component.scss']
})
export class ArticleFiltersComponent {

  ARTICLE_AREAS: ArticleArea[] = ARTICLE_AREAS;

  @Output()
  onFilter: EventEmitter<Partial<ArticleFilters>> = new EventEmitter();
  @Input()
  showExpenseFilter: boolean = true;

  private _defaultFilters: Partial<ArticleFilters>;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      area: "",
      code: "",
      name: "",
      expense: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getArticleFilters());
  }

  private _getArticleFilters(): Partial<ArticleFilters> {
    let filters: Partial<ArticleFilters> = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.area = values.area;
      filters.code = values.code;
      filters.name = values.name;
      filters.expense = values.expense;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): Partial<ArticleFilters> {
    return this._defaultFilters;
  }

  ngAfterViewInit() {
    this._setDefaultValues();
  }

  @Input()
  set defaultFilters(defaultFilters: Partial<ArticleFilters>) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
    this.applyFilters();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          area: this.defaultFilters.area,
          name: this.defaultFilters.name,
          code: this.defaultFilters.code,
          expense: this.defaultFilters.expense,
        })
        if (this.defaultFilters.project?.internal) {
          this.filtersForm.patchValue({
            area: "I",
          })
          this.filtersForm.get("area").updateValueAndValidity();
        }
      }
    }
  }
}
