import { Action, createReducer, on } from '@ngrx/store';

import { BillingLine, BillingLineDTO } from 'src/app/_models/billing-line.model';
import * as BillingLineActions from 'src/app/_store/billing-line.actions';
import { BillingLineFilters } from 'src/app/_models/billing-line.model';
import { removeObjectFromArray } from '../_helpers/utils';


export interface BillingLineState {
  list: BillingLineDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: Partial<BillingLineFilters>,
  dialogId: string,
  selectionDialogId: string,
  billingLine: BillingLine
  selectedBillingLines: BillingLine[]
};

const initialState: BillingLineState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  billingLine: null,
  selectedBillingLines: []
};

const billingLineReducer = createReducer(
  initialState,
  on(BillingLineActions.loadBillingLinesCompleted, (state, { billingLines, currentPage, total, perPage, order, direction, includes, filters }) => {
    return { ...state, list: billingLines, currentPage, total, perPage, order, direction, includes, filters };
  }),
  on(BillingLineActions.billingLineDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(BillingLineActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(BillingLineActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(BillingLineActions.addSelectBillingLines, (state, { billingLine }) => {
    return { ...state, selectedBillingLines: [...state.selectedBillingLines, billingLine] }
  }),
  on(BillingLineActions.removeSelectedBillingLines, (state, { billingLine }) => {
    const selectedBillingLines = removeObjectFromArray<BillingLine>(state.selectedBillingLines, billingLine);
    return { ...state, selectedBillingLines }
  }),
  on(BillingLineActions.resetSelectedBillingLines, (state) => {
    return { ...state, selectedBillingLines: [] }
  }),

);

export function reducer(state: BillingLineState | undefined, action: Action) {
  return billingLineReducer(state, action);
}



