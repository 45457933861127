<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="full-width-input">
        <mat-label>Da</mat-label>
        <input matInput [matDatepicker]="fromDatePicker" formControlName="from" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <mat-label>A</mat-label>
        <input matInput [matDatepicker]="toDatePicker" formControlName="to" />
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="full-width-input">
        <mat-label>Cliente</mat-label>
        <app-org-field formControlName="org"></app-org-field>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <mat-label>Identificativo fatturazione</mat-label>
        <input type="number" matInput formControlName="identifier">
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <mat-label>Con OC</mat-label>
        <mat-select formControlName="hasInvoice">
          <mat-option [value]="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="u-flex-full">
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">Filtra</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>