import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Article, ArticleFilters } from "src/app/_models/article";
import { CostExtra, CostExtraDTO } from "src/app/_models/costExtra";
import { AlertService } from "src/app/_services/alert.service";
import { State } from "src/app/_store";

import { DialogRef } from "@angular/cdk/dialog";
import { Activity, ActivityDTO } from "src/app/_models/activity";
import * as CostExtraActions from './../../../_store/cost-extra.actions';


@Component({
  selector: 'app-cost-extra-edit',
  templateUrl: './cost-extra-edit.component.html',
  styleUrls: ['./cost-extra-edit.component.scss']
})

export class CostExtraEditComponent implements OnInit {

  private _costExtra: CostExtra;
  articleFilters: Partial<ArticleFilters>;

  costExtraForm = this.fb.group({
    quantity: new FormControl<Number>(null, { validators: Validators.required }),
    singleCost: new FormControl<Number>(null, { validators: Validators.required }),
    article: new FormControl<Article>(null, { validators: Validators.required }),
    note: new FormControl<string>(''),
    isInvoice: new FormControl<boolean>(true),
  });


  constructor(private store$: Store<State>, private alertService: AlertService, private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { costExtra?: CostExtraDTO, activity: ActivityDTO }, private dialogRef: DialogRef<CostExtraEditComponent>) {
  }

  ngOnInit() {

    if (this.data && this.data.costExtra) {
      this.costExtra = new CostExtra(this.data.costExtra);
      console.log(this.costExtra)
    }
    this.ngOnChanges();
    this.articleFilters = {
      expense: true
    }
  }

  get costTotal(): number {
    if (this.costExtraForm) {
      return Number(this.costExtraForm.get('singleCost').value) * Number(this.costExtraForm.get('quantity').value)
    }
    return 0;
  }

  get costExtra(): CostExtra {
    return this._costExtra;
  }

  set costExtra(costExtra: CostExtra) {
    this._costExtra = costExtra;
    this.ngOnChanges();
  }

  save() {
    let unsavedEntity = this._prepareSave();
    this.store$.dispatch(CostExtraActions.saveCostExtra({ costExtra: unsavedEntity.toDTO() }));
  }

  private ngOnChanges() {
    if (this.costExtraForm) {
      this.costExtraForm.reset();
      this._initFormValues(this.costExtra);
    }
  }

  private _initFormValues(costExtra?: CostExtra) {
    if (costExtra) {
      this.costExtraForm.patchValue({
        quantity: costExtra.quantity,
        singleCost: costExtra.singleCost,
        article: costExtra.article,
        note: costExtra.note,
        isInvoice: costExtra.isInvoice,
      });
    } else { }
  }

  private _prepareSave(): CostExtra {
    let saving: CostExtra = CostExtra.fromFormGroup(this.costExtraForm, this.costExtra);
    saving.activity = new Activity(this.data.activity);
    return saving;
  }

  close() {
    if (this.costExtraForm.pristine) {
      this.store$.dispatch(CostExtraActions.closeCostExtraDialog({ dialogId: this.dialogRef.id }))
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(CostExtraActions.closeCostExtraDialog({ dialogId: this.dialogRef.id }))
          }
        });
    }
  }

  deleteCost() {
    if (this.costExtra) {
      this.store$.dispatch(CostExtraActions.deleteCostExtra({ costExtra: this.costExtra.toDTO() }))
    }
  }

  generateBillingLine() {
    if (this.costExtra) {
      this.store$.dispatch(CostExtraActions.generateBillingLine({ costExtra: this.costExtra.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
